import { Component, OnInit } from '@angular/core';
import { ClientInfo } from '../clientInfo.model';
import { HttpClient } from '@angular/common/http';
import { Globals } from '../../../globals';
import { log } from 'util';

@Component({
  selector: 'app-add-client',
  templateUrl: './add-client.component.html',
  styleUrls: ['./add-client.component.scss']
})
export class AddClientComponent implements OnInit {

  client = {
    info: {}
  };
  clients = [];

  constructor(private http: HttpClient) {
    this.client.info = new ClientInfo();
  }


  ngOnInit() {}
  addClient() {
    this.http.post(Globals.URL + 'clients', this.client).subscribe(response => {
    });
  }





}
