import {Component, OnInit} from '@angular/core';
import {single, multi} from '../data';
import {HttpClient} from '@angular/common/http';
import {Globals} from '../../../globals';
import {Router} from '@angular/router';
import {ToastrManager} from 'ng6-toastr-notifications';


@Component({
  selector: 'app-orders-value',
  templateUrl: './orders-value.component.html',
  styleUrls: ['./orders-value.component.sass']
})
export class OrdersValueComponent implements OnInit {

  single: any[];
  multi: any[];

  // options
  showXAxis = true;
  showYAxis = true;
  gradient = true;
  showLegend = true;
  showXAxisLabel = true;
  schemeType = 'Basis';
  xAxisLabel = 'Perioada';
  showYAxisLabel = true;
  yAxisLabel = 'Comenzi';

  colorScheme = {
    domain: ['#ff0000', '#ffff00', '#00bfff', '#ffbf00']
  };

  // line, area
  autoScale = true;

  GetChartData() {
    this.http.get(Globals.URL + 'getCalendarProfits').subscribe(response => {
      // this.multi.push(response);
      // this.multi = response;
      // multi.push(response)
    });
  }

  constructor(private router: Router, private http: HttpClient, public toastr: ToastrManager) {
    Object.assign(this, {multi});
  }

  ngOnInit() {
    this.checkAuth();
    this.GetChartData();
  }



  checkAuth() {
    if (localStorage.getItem('token_type') === null) {
      this.router.navigate(['']);
    }
  }

}
