export class Prices {
    type: number;
    emergency_tax: number;
    standad_carpet: number;
    high_carpet: number;
    standard_carpetExtra: number;
    high_carpetExtra: number;
    standard_blanket: number;
    high_blanket: number;
    standard_blanketExtra: number;
    high_blanketExtra: number;
    standard_pillow: number;
    high_pillow: number;
    standard_pillowExtra: number;
    high_pillowExtra: number;
    standard_other: number;
    high_other: number;
    standard_otherExtra: number;
    high_otherExtra: number;
    standard_delivery: number;
    high_delivery: number;
}
