export class ClientInfo {
  name: string;
  CUI: string;
  client_id: number;
  client_status: number;
  client_type: number;
  email: string;
  phone: string;
  street: string;
    observations: string;
   }
