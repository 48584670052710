import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../user.service';
import { Router } from '@angular/router';
import { UniqueNameValidatorDirective } from '../unique-name-validator.directive';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit {

  constructor(private formBuilder: FormBuilder, private router: Router, private userService: UserService) {
  }

  addForm: FormGroup;
  submitted = false;

  ngOnInit() {
    this.addForm = this.formBuilder.group({
      name: ['', Validators.required, UniqueNameValidatorDirective.createValidator(this.userService, '0')],
      password: ['', Validators.required],
      type: [1, Validators.required],
    });
  }
  get id() {
    return this.addForm.get('id');
  }

  get name() {
    return this.addForm.get('name');
  }

  get password() {
    return this.addForm.get('password');
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.addForm.invalid) {
      return;
    } else {
      this.userService.createUser(this.addForm.value)
        .subscribe(data => {
          document.getElementById('closeAddUserModal').click();
          this.router.navigateByUrl('admin/list-user', { skipLocationChange: true }).then(() =>
            this.router.navigate(['admin/settings']));
        });

    }
  }
}
