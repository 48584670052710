export let single = [
  {
    'name': 'Germany',
    'value': 8940000
  },
  {
    'name': 'USA',
    'value': 5000000
  },
  {
    'name': 'France',
    'value': 7200000
  }
];

export let multi = [
  {
    'name': 'Altele',
    'series': [
      {
        'value': 1000,
        'name': '2016-09-15T23:39:52.582Z'
      },
      {
        'value': 2000,
        'name': '2016-09-13T13:51:12.527Z'
      },
      {
        'value': 3000,
        'name': '2016-09-17T00:46:13.989Z'
      },
      {
        'value': 4000,
        'name': '2016-09-20T14:22:56.482Z'
      },
      {
        'value': 5000,
        'name': '2016-09-21T05:54:27.174Z'
      },
      {
        'name': '2016-09-18T06:26:01.252Z',
        'value': 6000
      },
      {
        'name': '2016-09-22T11:24:55.777Z',
        'value': 7000
      },
      {
        'name': '2016-09-21T18:05:14.770Z',
        'value': 8000
      },
      {
        'name': '2016-09-13T01:27:00.176Z',
        'value': 9000
      },
      {
        'name': '2016-09-18T18:33:57.852Z',
        'value': 10000
      }
    ]
  },
  {
    'name': 'Pilote',
    'series': [
      {
        'value': 4779,
        'name': '2016-09-15T23:39:52.582Z'
      },
      {
        'value': 2624,
        'name': '2016-09-13T13:51:12.527Z'
      },
      {
        'value': 2807,
        'name': '2016-09-17T00:46:13.989Z'
      },
      {
        'value': 6100,
        'name': '2016-09-20T14:22:56.482Z'
      },
      {
        'value': 4946,
        'name': '2016-09-21T05:54:27.174Z'
      },
      {
        'name': '2016-09-18T06:26:01.252Z',
        'value': 2386
      },
      {
        'name': '2016-09-22T11:24:55.777Z',
        'value': 6409
      },
      {
        'name': '2016-09-21T18:05:14.770Z',
        'value': 4262
      },
      {
        'name': '2016-09-13T01:27:00.176Z',
        'value': 3970
      },
      {
        'name': '2016-09-18T18:33:57.852Z',
        'value': 2776
      }
    ]
  },
  {
    'name': 'Perne',
    'series': [
      {
        'value': 5189,
        'name': '2016-09-15T23:39:52.582Z'
      },
      {
        'value': 4597,
        'name': '2016-09-13T13:51:12.527Z'
      },
      {
        'value': 4932,
        'name': '2016-09-17T00:46:13.989Z'
      },
      {
        'value': 2243,
        'name': '2016-09-20T14:22:56.482Z'
      },
      {
        'value': 3482,
        'name': '2016-09-21T05:54:27.174Z'
      },
      {
        'name': '2016-09-18T06:26:01.252Z',
        'value': 4246
      },
      {
        'name': '2016-09-22T11:24:55.777Z',
        'value': 5386
      },
      {
        'name': '2016-09-21T18:05:14.770Z',
        'value': 6011
      },
      {
        'name': '2016-09-13T01:27:00.176Z',
        'value': 4392
      },
      {
        'name': '2016-09-18T18:33:57.852Z',
        'value': 4095
      }
    ]
  },

  {
    'name': 'Covoare',
    'series': [
      {
        'value': 3591,
        'name': '2016-09-15T23:39:52.582Z'
      },
      {
        'value': 4563,
        'name': '2016-09-13T13:51:12.527Z'
      },
      {
        'value': 2000,
        'name': '2016-09-17T00:46:13.989Z'
      },
      {
        'value': 5947,
        'name': '2016-09-20T14:22:56.482Z'
      },
      {
        'value': 4067,
        'name': '2016-09-21T05:54:27.174Z'
      },
      {
        'name': '2016-09-18T06:26:01.252Z',
        'value': 2599
      },
      {
        'name': '2016-09-22T11:24:55.777Z',
        'value': 2240
      },
      {
        'name': '2016-09-21T18:05:14.770Z',
        'value': 4741
      },
      {
        'name': '2016-09-13T01:27:00.176Z',
        'value': 2620
      },
      {
        'name': '2016-09-18T18:33:57.852Z',
        'value': 6024
      }
    ]
  }
];
