import {Component, OnInit} from '@angular/core';
import {User} from '../user.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UserService} from '../user.service';
import {Router} from '@angular/router';
import {first} from 'rxjs/operators';
import {ToastrManager} from 'ng6-toastr-notifications';
import {UniqueNameValidatorDirective} from '../unique-name-validator.directive';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss'],
})


export class EditUserComponent implements OnInit {

  user: User;
  editForm: FormGroup;
  submitted = false;
  errorData = [];

  constructor(private formBuilder: FormBuilder, private router: Router, private userService: UserService, private toastr: ToastrManager) {
  }

  ngOnInit() {
    const userId = localStorage.getItem('editUserId');
    if (!userId) {
      // alert('Acțiune invalidă');
      // this.router.navigateByUrl('admin/list-user', {skipLocationChange: true}).then(() =>
      //   this.router.navigate(['admin/settings']));
      // return;
    }
    this.editForm = this.formBuilder.group({
      id: [],
      name: ['', Validators.required, UniqueNameValidatorDirective.createValidator(this.userService, userId)],
      password: ['', Validators.required],
      type: [1, Validators.required],
    });

    this.userService.getUserById(+userId)
      .subscribe(data => {
        this.editForm.setValue(data);
      });
  }

  get id() {
    return this.editForm.get('id');
  }

  get name() {
    return this.editForm.get('name');
  }

  get password() {
    return this.editForm.get('password');
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.editForm.invalid) {
      return;
    } else {
      this.userService.updateUser(this.editForm.value)
        .pipe(first())
        .subscribe(
          data => {
            document.getElementById('closeEditUserModal').click();
            this.router.navigateByUrl('admin/list-user', {skipLocationChange: true}).then(() =>
              this.router.navigate(['admin/settings']));
          },
          error => {
            this.errorData = [];
            this.errorData.push(error);
            this.toastr.errorToastr(this.errorData[0].error.errors.email, '');
            return;
          });
    }
  }

}
