import { Component, OnInit } from '@angular/core';
import { Settings } from './settings.model';
import { Globals } from '../../../globals';
import { HttpClient } from '@angular/common/http';
import { ToastrManager } from 'ng6-toastr-notifications';





@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class OrderComponent implements OnInit {

  settings;

  constructor(private http: HttpClient, public toastr: ToastrManager) {
    this.settings = new Settings();
  }

  ngOnInit() {
    this.getOrderDetails();
  }

  getOrderDetails() {
    this.http.get(Globals.URL + 'settings').subscribe(response => {
      this.settings = response;
    });
  }

  addOrderDetails() {
    // Post Settings
    this.http
      .patch(Globals.URL + 'settings', this.settings)
      .subscribe(
        result => {
          this.toastr.successToastr(Globals.mesageSuccess);
        },
        error => {
          this.toastr.errorToastr(Globals.mesageError);
        });
  }
}
