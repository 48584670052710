import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterName'
})
export class FilterName implements PipeTransform {

  transform(value: any, args?: any): any {

    if (!args) {
      return value;
    }

    if (value === undefined) {
      return value;
    }

    // return filtered items by name
    return value.filter(
      items => {

        // return items.name.toLowerCase().startsWith(args) === true;
        // return items.name.toLowerCase().startsWith(args) === true;

      });
  }
}
