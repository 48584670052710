import {Component, OnInit} from '@angular/core';
import { Location } from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {Globals} from '../../../globals';
import {Router, ActivatedRoute, ParamMap} from '@angular/router';
import {ToastrManager} from 'ng6-toastr-notifications';
import {SMS} from '../../_model/sms.model';
import {SMSService} from './../../_services/sms.service';


declare var $: any;

@Component({
  selector: 'app-clients-list',
  templateUrl: './clients-list.component.html',
  styleUrls: ['./clients-list.component.scss']
})
export class ClientsListComponent implements OnInit {
  configClient: any;
  clients = [];
  clientInfo = false;
  ordersList: any;
  currentOrderLength: any;
  currentName: any;
  sortId = 0;
  sortName = 0;
  clientsListLength: number;
  defaultPersonType = true;

  client = {
    info: {
      name: '',
      CUI: '',
      client_id: '',
      client_status: '',
      client_type: 0,
      client_problem: 0,
      email: '',
      phone: '',
      J: '',
      street: ''
    }
  };

  editedClient = {
    info: {
      name: '',
      CUI: '',
      client_id: '',
      client_status: '',
      client_type: 0,
      client_problem: 0,
      email: '',
      J: '',
      phone: '',
      street: ''
    }
  };

  az = true;
  string: any;

  smsText: string;

  SMS: SMS;

  orders: any = [];
  ordersNumber: number;

  logClient;

  constructor(
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    public toastr: ToastrManager,
    private smsService: SMSService
  ) {
    this.SMS = new SMS();

    this.configClient = {
      currentPage: 1,
      itemsPerPage: 20,
    }

    this.route.queryParamMap
      .map(params => params.get('pageClient'))
      .subscribe(
        pageClient => {
          this.configClient.currentPage = pageClient
        }
      );
  }

  ngOnInit() {
    this.checkAuth();
    this.getClients();
  }

  pageChangedClient(newPage: number) {
    this.configClient.currentPage = newPage;
    this.location.go('/admin/clients?pageClient=' + newPage);
  }

  getClients() {
    this.http.get(Globals.URL + 'clients').subscribe(response => {
      this.clients.push(response);
      this.clients[0] = response;
      this.clientsListLength = this.clients[0].length;
    });
  }

  loadEditedClient(client) {
    this.editedClient.info = client;
    console.log(this.editedClient.info);
  }

  addClient() {
    this.checkClient(this.client.info.name);
    this.checkClient(this.client.info.phone);
    // this.checkClient(this.client.info.email);
    this.checkClient(this.client.info.street);

    $(document).ready(function () {
      $('input[id=pfizica]').prop('checked', true);
    });


    if (this.clientInfo === false) {
      this.http
        .post(Globals.URL + 'clients', this.client)
        .subscribe(response => {
          // this.clients[0].push(this.client.info);
          this.getClients();
          this.toastr.successToastr('A fost adaugat un nou client', '');
          this.client.info = {
            name: '',
            CUI: '',
            client_id: '',
            client_status: '',
            client_type: 0,
            client_problem: 0,
            email: '',
            J: '',
            phone: '',
            street: ''
          };
        });
    }

  }

  checkClient(item) {

    if (item === '') {
      this.clientInfo = true;
    } else {
      this.clientInfo = false;
    }
  }

  addOrderForClient(item) {
    localStorage.setItem('clientID', item.client_id);
    localStorage.setItem('addOrderFromClient', 'true');
  }

  search(string) {
    if (string.length < 3) {
      this.getClients();
    }
    if (string.length >= 3) {
      this.http
        .get(Globals.URL + 'searchClient/' + string)
        .subscribe(response => {
          this.clients.push(response);
          this.clients[0] = response;
          this.clientsListLength = this.clients[0].length;
        });
    }
  }

  editUser(id) {
    if (this.editedClient.info.name !== '' && this.editedClient.info.phone !== '' && this.editedClient.info.street !== '') {
      this.http
        .put(Globals.URL + 'clients/' + id, this.editedClient)
        .subscribe(response => {
          this.toastr.successToastr('Datele clientului au fost modificate', '');
          $('#exampleModal').modal('hide');
        });
    } else {
      this.toastr.errorToastr('Numele, telefonul și adresa trebuie să fie completate', '');
    }
  }

  editUserWithoutClose(id) {
    if (this.editedClient.info.name !== '' && this.editedClient.info.phone !== '' && this.editedClient.info.street !== '') {
      this.http
        .put(Globals.URL + 'clients/' + id, this.editedClient)
        .subscribe(response => {
          this.toastr.successToastr('Datele clientului au fost modificate', '');
          // $('#exampleModal').modal('hide');
        });
    } else {
      this.toastr.errorToastr('Numele, telefonul și adresa trebuie să fie completate', '');
    }
  }

  deleteUser(item) {
    if (confirm('Ești sigur că vrei să faci acest client inactiv!?')) {
      this.http
        .patch(Globals.URL + 'softDeleteClient/' + item.client_id, item)
        .subscribe(response => {
          this.clients[0].splice(this.clients[0].indexOf(item), 1);
          this.toastr.successToastr('Clientul a fost sters', '');

          this.router.navigateByUrl('admin/orderNumber', {skipLocationChange: true}).then(() =>
            this.router.navigate(['admin/clients']));
        });
    }
  }

  getClientOrders(item) {
    this.http
      .get(Globals.URL + 'getOrdersByClient/' + item.client_id)
      .subscribe(response => {
        this.ordersList = response;
        if (this.ordersList[0]) {
          this.currentName = this.ordersList[0].info.name;
          this.currentOrderLength = this.ordersList.length;
        }
      });
  }

  sortClientById() {
    this.sortClient(this.sortId, 'sortClientById/');
  }

  sortClientByName() {
    this.sortClient(this.sortName, 'sortClientByName/');
  }

  sortClient(sort, api) {
    if (sort === 0) {
      this.http.get(Globals.URL + api + 0).subscribe(response => {
        this.clients[0] = response;
        this.clientsListLength = this.clients[0].length;
      });
    }
    if (sort === 1) {
      this.http.get(Globals.URL + api + 1).subscribe(response => {
        this.clients[0] = response;
        this.clientsListLength = this.clients[0].length;
      });
    }
  }


  checkAuth() {
    if (localStorage.getItem('token_type') === null) {
      this.router.navigate(['']);
    }
  }

  sendSMS() {

    this.SMS.message = this.smsText;
    this.SMS.number = null;

    if (confirm('Sunteți sigur că doriți să trimiteți mesaj tuturor clienților? ')) {
      this.smsService.postMultipleSMS(this.SMS).subscribe(
        response => {
          this.toastr.successToastr('Mesajul a fost transmis spre procesare!', 'Procesare!');
        },
        error => {
          this.toastr.errorToastr('Mesajul nu a fost livrat!', 'Eroare!');
        }
      );
    }
  }

  getHistoryMessage(item) {

    this.currentName = item.name;

    this.smsService.showLogByClient(item.client_id).subscribe(
      response => {
        this.logClient = response;
      },
      error => {

      }
    );
  }

  onlyNumbers(event) {
    return (event.charCode === 8 || event.charCode === 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  onlyAlpha(event: any) {
    const pattern = /^[a-zA-Z -]*$/;
    if (!pattern.test(event.target.value)) {
      event.target.value = event.target.value.replace(/[^a-zA-Z -]/g, '');
      // invalid character, prevent input
    }
  }

}
