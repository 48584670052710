import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from '../../../globals';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { error } from '@angular/compiler/src/util';
import { SMSService } from './../../_services/sms.service';


@Component({
  selector: 'app-inactive-clients-list',
  templateUrl: './inactive-clients-list.component.html',
  styleUrls: ['./inactive-clients-list.component.scss']
})
export class InactiveClientsListComponent implements OnInit {
  clients = [];
  clientsListLength: number;
  ordersList: any;
  currentOrderLength: any;
  currentName: any;
  currentClientHistoryName: string;
  az = true;
  inactiveList = false;
  string: any;

  logClient;

  client = {
    info: {
      name: '',
      CUI: '',
      client_id: '',
      client_status: '',
      client_type: 0,
      email: '',
      phone: '',
      J: '',
      street: '',
    }
  };

  editedInactiveClient = {
    info: {
      name: '',
      CUI: '',
      client_id: '',
      client_status: '',
      client_type: 0,
      email: '',
      J: '',
      phone: '',
      street: '',
    }
  };

  constructor(
    private http: HttpClient,
    private router: Router,
    public toastr: ToastrManager,
    public smsServices: SMSService
  ) {
    // this.editedInactiveClient.info = new ClientInfo();
    // this.client.info = new ClientInfo();
  }

  ngOnInit() {
    this.checkAuth();
    this.getClients();
  }

  getClients() {
    this.http.get(Globals.URL + 'getDeletedClients').subscribe(response => {
      this.clients = [];
      this.clients.push(response);
      if (this.clients[0]) {
        this.clientsListLength = this.clients[0].length;
      }
    },
      // tslint:disable-next-line:no-shadowed-variable
      error => {
      }
    );
  }

  addClient() {
    this.http.post(Globals.URL + 'clients', this.client).subscribe(response => {
      this.clients[0].push(this.client.info);
      this.toastr.successToastr('A fost adaugat un nou client', '');
    });
  }

  search(string) {
    if (string.length >= 3) {
      this.http
        .get(Globals.URL + 'searchDeletedClient/' + string)
        .subscribe(response => {
          this.clients.push(response);
          this.clients[0] = response;
          if (this.clients[0]) {
            this.clientsListLength = this.clients[0].length;
          }
        });
    } else if (string.length < 3) {
      this.getClients();
    }
  }

  addOrderForClient(item) {
    localStorage.setItem('clientID', item.client_id);
  }

  editUser(id) {
    this.http
      .put(Globals.URL + 'clients/' + id, this.editedInactiveClient)
      .subscribe(response => {
        this.toastr.successToastr('Datele clientului au fost modificate', '');
      });
  }

  deleteUser(item) {
    if (confirm('Ești sigur că vrei să ștergi acest client!?')) {
      this.http
        .delete(Globals.URL + 'clients/' + item.client_id)
        .subscribe(response => {
          this.clients[0].splice(this.clients[0].indexOf(item), 1);
          this.toastr.successToastr('Clientul a fost sters', '');
        });
    }
  }

  makeClientActive(item) {
    this.http
      .patch(Globals.URL + 'restoreClient/' + item.client_id, item)
      .subscribe(response => {
        this.clients[0].splice(this.clients[0].indexOf(item), 1);
        this.toastr.successToastr(
          'Clientul a fost mutat in lista de clienti activi',
          ''
        );
        this.router.navigateByUrl('admin/orderNumber', { skipLocationChange: true }).then(() =>
          this.router.navigate(['admin/clients']));
      });
  }

  getClientOrders(item) {
    this.http.get(Globals.URL + 'getOrdersByClient/' + item.client_id).subscribe(response => {
      this.ordersList = response;
      if (this.ordersList[0]) {
        this.currentName = this.ordersList[0].info.name;
        this.currentOrderLength = this.ordersList.length;
      }
    });
  }


  checkAuth() {
    if (localStorage.getItem('token_type') === null) {
      this.router.navigate(['']);
    }
  }

  getHistoryMessages(item) {

    this.currentClientHistoryName = item.name;

    this.smsServices.showLogByClient(item.client_id).subscribe(
      response => {
        this.logClient = response;
      },
      // tslint:disable-next-line:no-shadowed-variable
      error => {

      }
    );
  }

}
