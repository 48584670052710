import { Messages } from '../_model/default-messages.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Globals } from '../../globals';

@Injectable({
  providedIn: 'root'
})
export class DefaultMessagesService {

  constructor(private http: HttpClient) { }

  getMessages() {
    return this.http.get<Messages[]>(Globals.URL + 'getMessages');
  }

  postMessages(msgs) {
    return this.http.post(Globals.URL + 'createMessages', msgs);
  }

}
