import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from './user.model';
import { Globals } from '../../../globals';
import 'rxjs/add/operator/map';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) {
  }

  getUsers() {
    return this.http.get<User[]>(Globals.URL + 'getUsers');
  }

  getUserById(id: number) {
    return this.http.get<User>(Globals.URL + 'getUser/' + id);
  }

  createUser(user: User) {
    return this.http.post(Globals.URL + 'postUser', user);
  }

  updateUser(user: User) {
    return this.http.patch(Globals.URL + 'updateUser/' + user.id, user);
  }

  deleteUser(id: number) {
    return this.http.delete(Globals.URL + 'deleteUser/' + id);
  }

  getUserByName(name: string, id: string) {
    return this.http.post(Globals.URL + 'getUserByName', {
      name,
      id
    });
  }
}
