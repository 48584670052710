import {Directive} from '@angular/core';
import {AbstractControl, NG_ASYNC_VALIDATORS} from '@angular/forms';
import {UserService} from './user.service';
import {map} from 'rxjs/operators';


@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[uniqueName]',
  providers: [{provide: NG_ASYNC_VALIDATORS, useExisting: UniqueNameValidatorDirective, multi: true}]
})

export class UniqueNameValidatorDirective {
  static createValidator(userService: UserService, customerId: string) {
    return (control: AbstractControl) => {
      return userService.getUserByName(control.value, customerId).pipe(
        map(users => {
          return users && users !== null ? {'uniqueName': true} : null;
        }));
    };
  }
}
