import {Component, HostListener, OnInit, Input} from '@angular/core';
import { Location } from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {Globals} from '../../../globals';
import {Settings} from '../../settings/prices/settings.model';
import {Router, ActivatedRoute, ParamMap} from '@angular/router';
import {ToastrManager} from 'ng6-toastr-notifications';
import {DefaultMessagesService} from './../../_services/default-messages.service';
import {Messages} from './../../_model/default-messages.model';
import {SMS} from './../../_model/sms.model';
import {SMSService} from './../../_services/sms.service';

@Component({
  selector: 'app-list-order',
  templateUrl: './list-order.component.html',
  styleUrls: ['./list-order.component.scss']
})
export class ListOrderComponent implements OnInit {
  ordersList: any;
  currentOrderLength: any;
  currentName: any;

  public innerWidth: any;
  config: any;
  curentPage = 1;
  orders: any = [];
  prices;
  string: any;
  ordersListLength: number;
  nameFilter = '';
  ordersNumber: number;
  sortDate = 0;
  sortStatus = 0;
  sortId = 1;

  curentOrderInfo = {
    delivery2: false,
    remote: false,
    paid: false,
    observations: '',
    series: '',
    order_number: 0,
    finishedPrice: 1,
    id: '',
    name: '',
    phone: '',
    email: '',
    street: '',
    created_at: '',
    color: '',
    carpetsNumber: 0,
    blanketsNumber: 0,
    pillowsNumber: 0,
    othersNumber: 0,
    total_price: 0,
    advance_payment: 0,
    delivery: 0,
    emergency_tax: 0
  };
  currentOrderItems = {
    blanketNumber: 0,
    carpetNumber: 0,
    pillowNumber: 0
  };
  carpetInfo = {
    carpetL: 0,
    carpetW: 0
  };
  settings;
  userType: string;

  smsText: string;
  messageSMS: string;

  defaultMsg;

  currentOrder = {
    info: {
      email: '',
      name: '',
      phone: '',
      client_id: '',
      id: '',
      smsText: '',
      messageSMS: ''
    }
  };

  SMS: SMS;

  logOrder;
  orderNotificationID;

  constructor(
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private toastr: ToastrManager,
    private defaultMessages: DefaultMessagesService,
    private smsService: SMSService) {
    this.settings = new Settings();
    this.defaultMsg = new Messages();
    this.SMS = new SMS();

    this.config = {
      currentPage: 1,
      itemsPerPage: 20,
    }

    this.route.queryParamMap
      .map(params =>  params.get('pageOrders'))
      .subscribe(pageOrders => {
        this.config.currentPage = pageOrders
      });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  ngOnInit() {
    this.innerWidth = window.innerWidth;
    this.string = null;
    this.getPrices();
    this.getOrders();
    this.getUserType();
    this.getDefaultMessages();
  }

  pageChanged(newPage: number) {
    this.config.currentPage = newPage;
    this.location.go('/admin/listOrder?pageOrders=' + newPage);
  }

  refreshOrders() {
    this.getOrders();
  }

  getDefaultMessages() {
    this.defaultMessages.getMessages().subscribe(
      data => {
        this.defaultMsg = data;
        this.messageSMS = this.defaultMsg[0].messageMail;
        this.smsText = this.defaultMsg[0].messageSMS;
      }
    );
  }

  getUserType() {
    this.userType = localStorage.getItem('user_type');
  }

  getOrders() {
    this.http.get(Globals.URL + 'orders').subscribe(response => {
      if (response === null) {
        this.orders = 'null';
      } else {
        this.orders = response;
        this.ordersNumber = this.orders.length;
      }
    });
  }

  getClientOrders(item) {
    this.http
      .get(Globals.URL + 'getOrdersByClient/' + item.client_id)
      .subscribe(response => {
        this.ordersList = response;
        this.ordersList.reverse();
        if (this.ordersList[0]) {
          this.currentName = this.ordersList[0].info.name;
          this.currentOrderLength = this.ordersList.length;
        }
      });
  }

  getPrices() {
    this.http.get(Globals.URL + 'getCurrentPrices').subscribe(response => {
      this.prices = response;
    });
  }

  search(string) {
    if (string.length < 2) {
      this.getOrders();
    }
    if (string.length >= 2) {
      this.http
        .get(Globals.URL + 'searchOrders/' + string)
        .subscribe(response => {
          if (response === null) {
            this.orders = 'null';
          } else {
            this.orders = response;
            this.ordersNumber = this.orders.length;
          }
        });
    }
  }

  deleteOrders(item) {
    if (confirm('Ești sigur că vrei să ștergi aceasta comada!?')) {
      this.http
        .delete(Globals.URL + 'orders/' + item.info.id)
        .subscribe(result => {
          this.orders = this.orders.filter(u => u !== item);
        });
    }
  }

  editOrder(id) {
    this.http.get(Globals.URL + 'orders/' + id).subscribe(response => {
    });
  }

  deliveryOrder(item) {
    item.info.status = 0;
    this.http.patch(Globals.URL + 'orders/' + item.info.id, item).subscribe(result => {
    });
  }

  readyForDeliveryOrder(item) {
    item.info.status = 1;
    this.http.patch(Globals.URL + 'orders/' + item.info.id, item).subscribe(result => {
    });
  }

  finishOrder(item) {
    item.info.status = 2;
    this.http.patch(Globals.URL + 'orders/' + item.info.id, item).subscribe(result => {
      this.orders.splice(this.orders.indexOf(item.info.id), 1);

      // this.router.navigateByUrl('admin/orderNumber', {skipLocationChange: true}).then(() =>
      //   this.router.navigate(['admin/listOrder']));
      this.getOrders();


    });


  }

  sortById() {
    this.ordersSort(this.sortId, 'sortOrderById/');
  }

  sortByDate() {
    this.ordersSort(this.sortDate, 'sortOrderByDate/');
  }

  sortByStatus() {
    this.ordersSort(this.sortStatus, 'sortOrderByStatus/');
  }

  ordersSort(status, api) {
    if (status === 0) {
      this.getOrders();
    }
    if (status === 1) {
      this.http.get(Globals.URL + api + 0).subscribe(response => {
        this.orders = response;
      });
    }
    if (status === 2) {
      this.http.get(Globals.URL + api + 1).subscribe(response => {
        this.orders = response;
      });
    }
  }

  checkFinishedPrice() {
    if (this.curentOrderInfo.finishedPrice === 1) {
      return this.checkTotalBon(1);
    } else {
      return 'calculare ulterioară';
    }
  }

  checkTotalBon(type) {
    const delivery = +this.checkIfDelivery(this.curentOrderInfo.delivery);
    const emergency = +this.checkIfEmergency(this.curentOrderInfo.emergency_tax);
    const advancePayment = +this.curentOrderInfo.advance_payment;
    let total = 0;
    if (type === 0) {

      total = this.curentOrderInfo.total_price + delivery + emergency - advancePayment;

    } else {
      total = this.curentOrderInfo.total_price + delivery + emergency;
    }

    if (total == 0) {
      return 'achitată';
    } else {

      return total.toFixed(2) + ' lei';
    }
  }

  checkAdvance() {
    // if (this.curentOrderInfo.finishedPrice === 1) {
    if (this.curentOrderInfo.advance_payment !== 0) {
      return this.curentOrderInfo.advance_payment + ' lei';
    } else {
      return '-';
    }
    // } else {
    //   return '-';
    // }
  }

  checkDifference() {
    if (this.curentOrderInfo.paid === true) {
      return 'achitată';
    } else {
      if (this.curentOrderInfo.finishedPrice === 1) {
        if (this.curentOrderInfo.advance_payment !== 0) {
          return this.checkTotalBon(0);
        } else {
          return '-';
        }
      } else {
        return '-';
      }
    }

  }

  checkMeasures(item) {
    if (item !== null && item !== 0 && item !== ' ' && item !== '') {
      return item + ' mp';
    } else {
      return 'nemăsurat';
    }
  }

  printPDF() {

    localStorage.setItem('pdfInfo', JSON.stringify(this.curentOrderInfo));
  }

  showOrderInfo(item) {
    this.curentOrderInfo = item.info;
    this.curentOrderInfo.finishedPrice = 1;
    this.checkIfFinishedPrice(item);
    this.http.get(Globals.URL + 'settings').subscribe(response => {
      this.settings = response;
    });
  }

  checkIfFinishedPrice(order) {

    for (const item of order.carpetsList) {
      if (item.price === 0) {
        this.curentOrderInfo.finishedPrice = 0;
      }
    }
    for (const item of order.othersList) {
      if (item.price === 0) {
        this.curentOrderInfo.finishedPrice = 0;
      }
    }
  }

  checkIfFinishedPriceList(order) {

    for (const item of order.carpetsList) {
      if (item.price === 0) {
        return 'nefinalizată';
      }
    }
    for (const item of order.othersList) {
      if (item.price === 0) {
        return 'nefinalizată';
      }
    }
    const delivery = +this.checkIfDelivery(order.info.delivery);
    const emergency = +this.checkIfEmergency(order.info.emergency_tax);

    const total = order.info.total_price + delivery + emergency;
    return total.toFixed(2) + ' lei';
  }

  checkIfEmergency(emergency) {
    if (emergency === 1) {
      // console.log(this.prices.emergency_tax);
      return this.prices.emergency_tax;
    } else {
      return 0;
    }
  }

  checkIfDelivery(delivery) {
    if (delivery === 1) {
      return this.prices.delivery;
    } else {
      return 0;
    }
  }

  checkIfFinishedPriceListDifference(order) {

    if (order.info.paid === true) {
      return 'achitat';
    } else {
      for (const item of order.carpetsList) {
        if (item.price === 0) {
          return 'nefinalizată';
        }
      }
      for (const item of order.othersList) {
        if (item.price === 0) {
          return 'nefinalizată';
        }
      }

    }

    const advancePayment = +order.info.advance_payment;
    const delivery = +this.checkIfDelivery(order.info.delivery);
    const emergency = +this.checkIfEmergency(order.info.emergency_tax);
    const total_price = order.info.total_price + delivery + emergency;

    const difference = total_price - advancePayment;

    if (order.info.paid === true) {
      return 'achitat';
    } else {
      if (advancePayment === 0) {
        return total_price.toFixed(2) + ' lei';
      } else if (advancePayment < total_price) {
        return difference.toFixed(2) + ' lei';
      } else if (advancePayment === total_price) {
        return 'achitat';
      } else if (advancePayment > total_price) {
        return 'avans prea mare';
      }
    }
  }

  // Send Email
  chooseOrderForEmail(item) {
    this.currentOrder.info = item.info;
  }

  sendEmail() {
    this.currentOrder.info.smsText = this.messageSMS;
    this.http.post(Globals.URL + 'sendEmail', this.currentOrder.info).subscribe(
      data => {
        this.toastr.successToastr('Mesajul a fost livrat!', 'Succes !');
      }
      , err => {
        this.toastr.successToastr('Mesajul a fost livrat!', 'Succes !');
      }
    );
  }

  // *********


  chooseOrderForSms(item) {
    this.currentOrder.info = item.info;
  }

  sendSMS() {

    this.SMS.number = this.currentOrder.info.phone;
    this.SMS.message = this.smsText;
    this.SMS.client_id = this.currentOrder.info.client_id;
    this.SMS.id = this.currentOrder.info.id;


    this.smsService.postSMS(this.SMS).subscribe(
      response => {
        this.toastr.successToastr('Mesajul a fost transmis spre procesare!', 'Procesare!');
      },
      // tslint:disable-next-line:no-shadowed-variable
      error => {
        this.toastr.errorToastr('Mesajul nu a fost livrat!', 'Eroare!');
      });

  }

  getHistoryMessage(item) {

    this.orderNotificationID = item.info.id;

    this.smsService.showLogByOrder(item.info.id).subscribe(
      response => {
        this.logOrder = response;
      },
      // tslint:disable-next-line:no-shadowed-variable
      error => {
      }
    );

  }

  changeColor(color) {
    if (color == 'mov') {
      return 'purple';
    } else if (color == 'roșu') {
      return 'red';
    } else if (color == 'verde') {
      return 'green';
    } else if (color == 'galben') {
      return 'yellow';
    } else if (color == 'alb') {
      return 'white';
    } else if (color == 'albastru') {
      return 'blue';
    }
  }

  clearOrder() {

    localStorage.removeItem('order');
    localStorage.removeItem('clientID');

  }
}
