import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Globals} from '../globals';
import {Settings} from '../admin/settings/prices/settings.model';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrManager} from 'ng6-toastr-notifications';

declare var $;

@Component({
  selector: 'app-order-pdf',
  templateUrl: './order-pdf.component.html',
  styleUrls: ['./order-pdf.component.scss']
})
export class OrderPdfComponent implements OnInit {
  localOrder;
  settings;
  // curentOrderInfo = {
  //   id: '',
  //   name: '',
  //   phone: '',
  //   email: '',
  //   street: '',
  //   created_at: '',
  //   carpetsNumber: 0,
  //   blanketsNumber: 0,
  //   pillowsNumber: 0,
  //   othersNumber: 0,
  //   total_price: 0,
  //   advance_payment: 0
  // };

  curentOrderInfo = {
    paid: false,
    delivery2: false,
    id: 0,
    finishedPrice: 0,
    color: '',
    created_at: '',
    name: '',
    email: '',
    street: '',
    phone: '',
    city: '',
    number: '',
    observations: '',
    received_date: '',
    advance_payment: '',
    delivery: 0,
    carpetMeters: 0,
    status: 0,
    finished_at: '',
    total_price: 0,
    delivery_date: '',
    carpetTotalPrice: 0,
    blanketTotalPrice: 0,
    pillowTotalPrice: 0,
    otherTotalPrice: 0,
    series: '',
    order_number: '',
    emergency_tax: 0,
    client_type: 0,
    CUI: '',
    J: '',
    carpetsNumber: null,
    blanketNumber_old: 0,
    blanketsNumber: null,
    pillowsNumber: null,
    pillowsNumber_old: 0,
    othersNumber: null,
    othersNumber_old: 0,
    otherTotalPrice_old: 0
  };
  prices;


  constructor(private http: HttpClient,
              private router: Router,
              private route: ActivatedRoute,
              public toastr: ToastrManager) {
    this.settings = new Settings();

  }

  ngOnInit() {
    this.getPrices();
    this.getPdf();
    this.checkAuth();
  }


  checkAuth() {
    if (localStorage.getItem('token_type') === null) {
      this.router.navigate(['']);
    }
  }

  getPrices() {
    this.http.get(Globals.URL + 'getCurrentPrices').subscribe(response => {
      this.prices = response;
    });
  }

  getPdf() {
    this.http.get(Globals.URL + 'settings').subscribe(response => {
      this.settings = response;
      this.localOrder = localStorage.getItem('pdfInfo');
      this.localOrder = JSON.parse(this.localOrder);
      this.curentOrderInfo = this.localOrder;

      console.log(response);
      console.log(this.curentOrderInfo);

      // display print dialog and onfinishdialog redirect to listorders

      setTimeout(() => {
        window.print();
        setTimeout(() => {
          this.router.navigate(['admin/listOrder']);
        }, 500);

      }, 500);


    });


  }

  checkFinishedPrice() {
    if (this.curentOrderInfo.finishedPrice === 1) {
      return this.checkTotalBon(1);
      // return this.curentOrderInfo.total_price + ' lei';
    } else {
      return 'calculare ulterioară';
    }
  }

  checkAdvance() {
    // if (this.curentOrderInfo.finishedPrice === 1) {
    if (this.curentOrderInfo.advance_payment !== ' ') {
      return this.curentOrderInfo.advance_payment + ' lei';
    } else {
      return '-';
    }
    // } else {
    //   return '-';
    // }
  }

  checkIfEmergency(emergency) {
    if (emergency === 1) {
      // console.log(this.prices.emergency_tax);
      return this.prices.emergency_tax;
    } else {
      return 0;
    }
  }

  checkIfDelivery(delivery) {
    if (delivery === 1) {
      console.log(this.prices);
      return this.prices.delivery;
    } else {
      return 0;
    }
  }

  checkTotalBon(type) {
    console.log(this.curentOrderInfo.delivery);
    const delivery = +this.checkIfDelivery(this.curentOrderInfo.delivery);
    const emergency = +this.checkIfEmergency(this.curentOrderInfo.emergency_tax);
    const advancePayment = +this.curentOrderInfo.advance_payment;
    let total = 0;
    if (type === 0) {

      total = this.curentOrderInfo.total_price + delivery + emergency - advancePayment;

    } else {
      total = this.curentOrderInfo.total_price + delivery + emergency;
    }

    if (total == 0) {
      return 'achitată';
    } else {

      return total.toFixed(2) + ' lei';
    }
  }

  checkDifference() {
    if (this.curentOrderInfo.paid === true) {
      return 'achitată';
    } else {
      if (this.curentOrderInfo.finishedPrice === 1) {
        if (this.curentOrderInfo.advance_payment !== ' ') {

          // const advancePayment = +this.curentOrderInfo.advance_payment;
          // const total = this.curentOrderInfo.total_price - advancePayment;
          // if (total == 0) {
          //   return 'achitată';
          // } else {
          //
          //   return total + ' lei';
          // }

          return this.checkTotalBon(0);
        } else {
          return '-';
        }
      } else {
        return '-';
      }
    }

  }
}
