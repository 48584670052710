import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-main-settings',
  templateUrl: './main-settings.component.html',
  styleUrls: ['./main-settings.component.scss']
})
export class MainSettingsComponent implements OnInit {

  expand = 1;
  firstExpand = true;

  constructor() { }

  ngOnInit() {
  }

  expandOrderNumber() {
    this.expand = 1;
    this.firstExpand = false;
  }

  expandPrices() {
    this.expand = 2;
    this.firstExpand = false;
  }

  // expandAccounts() {
  //   this.expand = 3;
  // }

  expandSettings() {
    this.expand = 4;
    this.firstExpand = false;
  }

  expandDefaultMessages() {
    this.expand = 5;
    this.firstExpand = false;
  }
}
