import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from '../globals';
import { Router } from '@angular/router';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  user = {
    name: '',
    password: '',
    type: ''
  };

  constructor(private http: HttpClient, private router: Router) { }

  ngOnInit() {
    this.checkAuth();
  }


  saveUser() {
    this.http.post(Globals.URL + 'registerAdmin', this.user).subscribe(response => {
    });
  }


  checkAuth() {
    if (localStorage.getItem('token_type') === null) {
      this.router.navigate(['']);
    }
  }

}
