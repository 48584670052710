import { OrdersAdvanceComponent } from './admin/statistics/orders-advance/orders-advance.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { TokenInterceptor } from './token-interceptor.service';
import { AppComponent } from './app.component';
import { AddOrderComponent } from './admin/orders/add-order/add-order.component';
import { AdminNavBarComponent } from './admin/admin-nav-bar/admin-nav-bar.component';
import { AdminHeaderComponent } from './admin/admin-header/admin-header.component';
import { PricesComponent } from './admin/settings/prices/prices.component';
import { ToastrModule } from 'ng6-toastr-notifications';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OrderNumberComponent } from './admin/settings/order-number/order-number.component';
import { ListOrderComponent } from './admin/orders/list-order/list-order.component';
import { MyFilterPipe } from './my-filter.pipe';
import { ClientsComponent } from './admin/clients/clients/clients.component';
import { ClientsListComponent } from './admin/clients/clients-list/clients-list.component';
import { AddClientComponent } from './admin/clients/add-client/add-client.component';
import { RegisterComponent } from './register/register.component';
import { StatisticsComponent } from './admin/statistics/statistics.component';
import { LoginComponent } from './login/login.component';
import { FilterName } from './admin/orders/add-order/filterName.pipe';
import { FilterPhone } from './admin/orders/add-order/filterPhone.pipe';
import { OrdersStatisticsComponent } from './admin/statistics/orders-statistics/orders-statistics.component';
import { OrdersValueComponent } from './admin/statistics/orders-value/orders-value.component';
import { InactiveClientsListComponent } from './admin/clients/inactive-clients-list/inactive-clients-list.component';
import { AddOrderForClientComponent } from './admin/orders/add-order-for-client/add-order-for-client.component';
import { FishedOrdersListComponent } from './admin/orders/fished-orders-list/fished-orders-list.component';
import { OrdersComponent } from './admin/orders/orders/orders.component';
import { OrderPdfComponent } from './order-pdf/order-pdf.component';
import { AdminComponent } from './admin/admin.component';
import { MainSettingsComponent } from './admin/settings/main-settings/main-settings.component';
import { OrderComponent } from './admin/settings/order/order.component';
import { AddUserComponent } from './admin/settings/users/add-user/add-user.component';
import { EditUserComponent } from './admin/settings/users/edit-user/edit-user.component';
import { ListUserComponent } from './admin/settings/users/list-user/list-user.component';
import { UserService } from './admin/settings/users/user.service';
import { UniqueNameValidatorDirective } from './admin/settings/users/unique-name-validator.directive';
import { DefaultMessagesComponent } from './admin/settings/default-messages/default-messages.component';
import { JwtInterceptor } from './admin/_helpers/jwt.interceptor';

@NgModule({
  declarations: [
    AppComponent,
    AddOrderComponent,
    AdminNavBarComponent,
    AdminHeaderComponent,
    PricesComponent,
    OrderNumberComponent,
    ListOrderComponent,
    MyFilterPipe,
    ClientsComponent,
    ClientsListComponent,
    AddClientComponent,
    RegisterComponent,
    StatisticsComponent,
    LoginComponent,
    FilterName,
    FilterPhone,
    OrdersStatisticsComponent,
    OrdersValueComponent,
    InactiveClientsListComponent,
    AddOrderForClientComponent,
    FishedOrdersListComponent,
    OrdersComponent,
    OrderPdfComponent,
    AdminComponent,
    MainSettingsComponent,
    AddUserComponent,
    EditUserComponent,
    ListUserComponent,
    OrderComponent,
    UniqueNameValidatorDirective,
    OrdersAdvanceComponent,
    DefaultMessagesComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AngularFontAwesomeModule,
    ToastrModule.forRoot(),
    NgxPaginationModule,
    NgxChartsModule,
    RouterModule.forRoot([
      {
        path: '',
        component: LoginComponent,
        // redirectTo: 'admin/listOrder',
        // pathMatch: 'full'
      },
      {
        path: 'pdf',
        component: OrderPdfComponent
      },
      {
        path: 'admin',
        component: AdminComponent,
        children: [
          {
            path: 'register',
            component: RegisterComponent
          },
          {
            path: '',
            component: OrdersComponent
          },
          {
            path: 'settings',
            component: MainSettingsComponent,
          },
          {
            path: 'prices',
            component: PricesComponent
          },
          {
            path: 'statistics',
            component: StatisticsComponent
          },
          {
            path: 'clients/:id',
            component: ClientsComponent
          },
          {
            path: 'clients',
            component: ClientsComponent
          },
          {
            path: 'listOrder',
            component: OrdersComponent
          },
          {
            path: 'orderNumber',
            component: OrderNumberComponent
          },
          {
            path: 'order/:id',
            component: AddOrderComponent
          },
          {
            path: 'addOrder',
            component: AddOrderComponent
          },
          {
            path: 'addOrderForClient',
            component: AddOrderForClientComponent
          },
          {
            path: 'add-user',
            component: AddUserComponent
          },
          {
            path: 'list-user',
            component: ListUserComponent
          },
          {
            path: 'edit-user',
            component: EditUserComponent
          },
        ]
      },
      {
        path: '**',
        redirectTo: '',
        pathMatch: 'full'
      }
    ])
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    // UserService,
  ],
  bootstrap: [AppComponent],
  entryComponents: [EditUserComponent]
})
export class AppModule {
}


