import {
  Component, OnInit, ViewChild,
  ViewContainerRef,
  ComponentFactoryResolver,
  ComponentRef,
  ComponentFactory
} from '@angular/core';
import {User} from '../user.model';
import {Router} from '@angular/router';
import {UserService} from '../user.service';
import {EditUserComponent} from '../edit-user/edit-user.component';

@Component({
  selector: 'app-list-user',
  templateUrl: './list-user.component.html',
  styleUrls: ['./list-user.component.scss']
})
export class ListUserComponent implements OnInit {

  users: User[];

  @ViewChild('editContainer', {read: ViewContainerRef}) entry: ViewContainerRef;

  constructor(private router: Router, private userService: UserService, private resolver: ComponentFactoryResolver) {

  }

  ngOnInit() {
    this.getUser();
  }

  getUser() {
    this.userService.getUsers()
      .subscribe(data => {
        this.users = data;
      });
  }

  deleteUser(user: User): void {
    this.userService.deleteUser(user.id)
      .subscribe(data => {
        this.users = this.users.filter(u => u !== user);
      });
  }

  // createComponent(message) {
  //
  //   componentRef.instance.message = message;
  // }
  editUser(user: User): void {
    localStorage.removeItem('editUserId');
    localStorage.setItem('editUserId', user.id.toString());
    this.entry.clear();
    const factory = this.resolver.resolveComponentFactory(EditUserComponent);
    const componentRef = this.entry.createComponent(factory);
    componentRef.instance.ngOnInit();
    // this.router.navigate(['admin/edit-user'], {skipLocationChange: true});
    // this.router.navigateByUrl('admin/edit-user', {skipLocationChange: true});
  }

  addUser(): void {
    this.router.navigate(['admin/add-user']);
  }
}
