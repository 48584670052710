import { log } from 'util';
import { Component, OnInit } from '@angular/core';
import { DefaultMessagesService } from '../../_services/default-messages.service';
import { Messages } from '../../_model/default-messages.model';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Globals } from './../../../globals';



@Component({
  selector: 'app-default-messages',
  templateUrl: './default-messages.component.html',
  styleUrls: ['./default-messages.component.scss']
})
export class DefaultMessagesComponent implements OnInit {

  msgs;
  selectedMail: string;
  selectedSMS: string;


  constructor(private messagesService: DefaultMessagesService, private toastr: ToastrManager) {
    this.msgs = new Messages();
  }

  ngOnInit() {
    this.getMessages();
  }


  getMessages() {
    this.messagesService.getMessages().subscribe(data => {
      this.selectedMail = data[0]['messageMail'];
      this.selectedSMS = data[0]['messageSMS'];
    });
  }

  defaultMessages() {
    this.msgs.messageSMS = document.querySelector('#default-messages-select-SMS')['value'];
    this.msgs.messageMail = document.querySelector('#default-messages-select-MAIL')['value'];

    this.msgs.messageSMS = this.selectedSMS;
    this.msgs.messageMail = this.selectedMail;


    this.messagesService.postMessages(this.msgs).subscribe(
      data => {
        this.toastr.successToastr('Salvarea s-a efectuat!', 'Succes !');
      },
      error => {
        this.toastr.errorToastr('Salvarea nu a putut fi efectuată!', Globals.mesageError);
      });
  }

}
