import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from '../../../globals';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router } from '@angular/router';


@Component({
  selector: 'app-orders-statistics',
  templateUrl: './orders-statistics.component.html',
  styleUrls: ['./orders-statistics.component.sass']
})
export class OrdersStatisticsComponent implements OnInit {
  single: any[];

  multi: any;

  // options
  showXAxis = true;
  showYAxis = true;
  gradient = true;
  showLegend = true;
  showXAxisLabel = true;
  schemeType = 'Basis';
  xAxisLabel = 'Perioada';
  showYAxisLabel = true;
  yAxisLabel = 'Comenzi';

  colorScheme = {
    domain: ['#00bfff', '#ff0000', '#ffff00', '#ffbf00']
  };

  // line, area
  autoScale = true;

  GetChartData() {
    this.http.get(Globals.URL + 'getCalendarProfits').subscribe(response => {
      this.multi = response;
      Object.assign(this, response);
    });
  }

  constructor(private router: Router, private http: HttpClient, public toastr: ToastrManager) {
  }

  ngOnInit() {
    this.checkAuth();
    this.GetChartData();
  }


  checkAuth() {
    if (localStorage.getItem('token_type') === null) {
      this.router.navigate(['']);
    }
  }


}
