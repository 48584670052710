import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterPhone'
})
export class FilterPhone implements PipeTransform {

  transform(value: any, args?: any): any {

    if (!args) {
      return value;
    }

    if (value === undefined) {
      return value;
    }

    // return filtered items by phone
    return value.filter(
      items => {
        return items.phone.startsWith(args) === true;
      });
  }

}
