import {Component, OnInit} from '@angular/core';
import {Globals} from '../../globals';
import {HttpClient} from '@angular/common/http';
import {ToastrManager} from 'ng6-toastr-notifications';
import {Router} from '@angular/router';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss']
})
export class StatisticsComponent implements OnInit {

  single: any[];
  multi: any[];
  expand: number;
  firstExpand = true;

  // options
  showXAxis = true;
  showYAxis = true;
  gradient = true;
  showLegend = true;
  showXAxisLabel = true;
  schemeType = 'Basis';
  xAxisLabel = 'Perioada';
  showYAxisLabel = true;
  yAxisLabel = 'Comenzi';

  colorScheme = {
    domain: ['#ff0000', '#ffff00', '#00bfff', '#ffbf00']
  };

  // line, area
  autoScale = true;

  constructor(private router: Router, private http: HttpClient, public toastr: ToastrManager) {
    // Object.assign(this, this.multi);
  }

  GetChartData() {
    this.http.get(Globals.URL + 'getCalendarProfits').subscribe(response => {
      // this.multi.push(response);
    });
  }

  ngOnInit() {
    this.checkAuth();
    this.expand = 1;
    // this.GetChartData();
  }


  checkAuth() {
    if (localStorage.getItem('token_type') === null) {
      this.router.navigate(['']);
    }
    if (localStorage.getItem('user_type') === '0') {
      {
        this.router.navigate(['']);
      }
    }
  }

  expandOrderNumber() {
    this.expand = 1;
    this.firstExpand = false;
  }

  expandPrices() {
    this.expand = 2;
  }
}
