import { Component, OnInit } from '@angular/core';
import { Globals } from '../../../globals';
import { HttpClient } from '@angular/common/http';
import { ToastrManager } from 'ng6-toastr-notifications';
import { OrderNumber } from './OrderNumber.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-order-number',
  templateUrl: './order-number.component.html',
  styleUrls: ['./order-number.component.scss']
})
export class OrderNumberComponent implements OnInit {

  orderNumber;


  constructor(private http: HttpClient, public toastr: ToastrManager, private router: Router) {
    this.orderNumber = new OrderNumber();
  }

  ngOnInit() {
    this.checkAuth();
    this.getOrderNumber();
  }

  getOrderNumber() {
    this.http.get(Globals.URL + 'orderNumber').subscribe(response => {
      this.orderNumber = response;
    });
  }


  addOrderNumber() {
    this.http
      .patch(Globals.URL + 'orderNumber', this.orderNumber)
      .subscribe(
        result => {
          this.toastr.successToastr('', 'Talon actualizat');
        },
        error => {
          this.toastr.errorToastr(Globals.mesageError);
        });
  }

  checkNumber() {
    if (this.orderNumber.number > 1000) {
      this.orderNumber.number = 1000;
    }
  }

  checkAuth() {
    if (localStorage.getItem('token_type') === null) {
      this.router.navigate(['']);
    }
  }

}
