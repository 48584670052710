import { Component, OnInit } from '@angular/core';
import { Globals } from '../../../globals';
import { Router } from '@angular/router';
@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss']
})
export class ClientsComponent implements OnInit {

  constructor(private router: Router) { }


  ngOnInit() {
    this.checkAuth();
  }


  checkAuth() {
    if (localStorage.getItem('token_type') === null) {
      this.router.navigate(['']);
    }
  }

}
