// import { Settings } from './settings.model';
import { Globals } from '../../../globals';
import { Prices } from './prices.model';

import { Component, OnInit } from '@angular/core';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router } from '@angular/router';

// @Injectable()
@Component({
  selector: 'app-prices',
  templateUrl: './prices.component.html',
  styleUrls: ['./prices.component.scss'],
  providers: [Globals]
})
export class PricesComponent implements OnInit {
  prices;
  // settings;

  constructor(private router: Router, private http: HttpClient, public toastr: ToastrManager) {
    this.prices = new Prices();
  }

  ngOnInit() {
    this.checkAuth();
    this.getPrices();
  }

  getPrices() {
    this.http.get(Globals.URL + 'prices').subscribe(response => {
      this.prices = response;
    });
    // this.http.get(Globals.URL + 'settings').subscribe(response => {
    //   this.settings = response;
    // });
  }

  addPrices() {
    this.http
      .patch(Globals.URL + 'prices', this.prices)
      .subscribe(
        result => {
          this.toastr.successToastr(Globals.mesageSuccess);
        },
        error => {
          this.toastr.errorToastr(Globals.mesageError);
        });
    // Post Settings
    // this.http
    // .patch(Globals.URL + 'settings', this.settings)
    // .subscribe(
    //   result => {

    //   },
    //   error => {
    //   });
  }



  checkAuth() {
    if (localStorage.getItem('token_type') === null) {
      this.router.navigate(['']);
    }
  }

}
