import { log } from 'util';
import { OrderNumber } from '../../settings/order-number/OrderNumber.model';
import { Component, HostListener, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from '../../../globals';
import { Settings } from '../../settings/prices/settings.model';
import { Router } from '@angular/router';


@Component({
  selector: 'app-orders-advance',
  templateUrl: './orders-advance.component.html',
  styleUrls: ['./orders-advance.component.scss']
})
export class OrdersAdvanceComponent implements OnInit {


  public innerWidth: any;

  curentPage = 1;
  orders: any = [];
  prices;
  string: any;
  ordersListLength: number;
  nameFilter = '';
  ordersNumber: number;
  sortDate = 0;
  sortStatus = 0;
  sortId = 1;
  curentOrderInfo = {
    id: '',
    name: '',
    phone: '',
    email: '',
    street: '',
    created_at: '',
    color: '',
    carpetsNumber: 0,
    blanketsNumber: 0,
    pillowsNumber: 0,
    othersNumber: 0,
    total_price: 0,
    advance_payment: 0
  };
  settings;
  userType: string;

  smsText = 'Comanda dvs a fost finalizata.';
  currentOrder = {
    info: {
      name: '',
      phone: '',
      client_id: '',
      id: '',
      smsText: ''
    }
  };

  advance;


  constructor(private http: HttpClient, private router: Router) {
    this.settings = new Settings();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  ngOnInit() {
    this.innerWidth = window.innerWidth;
    this.getOrders();
    this.getPrices();
    this.getUserType();

    this.getAdvance();

  }

  getAdvance() {
    this.http.get(Globals.URL + 'getAdvance').subscribe(response => {
      this.advance = response[0][0];
      this.ordersNumber = this.advance.length;
    });
  }

  getUserType() {
    this.userType = localStorage.getItem('user_type');
  }

  getOrders() {
    this.http.get(Globals.URL + 'orders').subscribe(response => {
      this.orders.push(response);
      this.orders = response;
      // this.ordersNumber = this.orders.length;
    });
  }

  getPrices() {
    this.http.get(Globals.URL + 'prices').subscribe(response => {
      this.prices = response;
    });
  }

  search(string) {
    if (string.length < 3) {
      this.getOrders();
    }
    if (string.length >= 3) {
      this.http
        .get(Globals.URL + 'searchOrders/' + string)
        .subscribe(response => {
          this.orders.push(response);
          this.orders = response;
          // this.ordersListLength = this.orders.length;
        });
    }
  }

  deleteOrders(item) {
    if (confirm('Ești sigur că vrei să ștergi aceasta comada!?')) {
      this.http
        .delete(Globals.URL + 'orders/' + item.info.id)
        .subscribe(result => {
          this.orders = this.orders.filter(u => u !== item);
        });
    }
  }

  editOrder(id) {
    this.http.get(Globals.URL + 'orders/' + id).subscribe(response => {
    });
  }

  deliveryOrder(item) {
    item.info.status = 0;
  }

  readyForDeliveryOrder(item) {
    item.info.status = 1;
    this.http.patch(Globals.URL + 'orders/' + item.info.id, item).subscribe(result => {
    });
  }

  finishOrder(item) {
    item.info.status = 2;
    this.http.patch(Globals.URL + 'orders/' + item.info.id, item).subscribe(result => {
      this.orders.splice(this.orders.indexOf(item.info.id), 1);

      this.router.navigateByUrl('admin/orderNumber', { skipLocationChange: true }).then(() =>
        this.router.navigate(['admin/listOrder']));

    });


  }

  sortById() {
    this.ordersSort(this.sortId, 'sortOrderById/');
  }

  sortByDate() {
    this.ordersSort(this.sortDate, 'sortOrderByDate/');
  }

  sortByStatus() {
    this.ordersSort(this.sortStatus, 'sortOrderByStatus/');
  }

  ordersSort(status, api) {
    if (status === 0) {
      this.getOrders();
    }
    if (status === 1) {
      this.http.get(Globals.URL + api + 0).subscribe(response => {
        this.orders = response;
      });
    }
    if (status === 2) {
      this.http.get(Globals.URL + api + 1).subscribe(response => {
        this.orders = response;
      });
    }
  }

  printPDF() {
    localStorage.setItem('pdfInfo', JSON.stringify(this.curentOrderInfo));
  }

  showOrderInfo(item) {
    this.curentOrderInfo = item.info;
    this.http.get(Globals.URL + 'settings').subscribe(response => {
      this.settings = response;
    });
  }


  // Send Email
  chooseOrderForEmail(item) {
    this.currentOrder.info = item.info;
  }

  sendEmail() {
    this.currentOrder.info.smsText = this.smsText;
    this.http.post(Globals.URL + 'sendEmail', this.currentOrder.info).subscribe(response => {
    });
  }

  // *********


  chooseOrderForSms(item) {
    this.currentOrder.info = item.info;
  }

  sendSMS() {
    this.currentOrder.info.smsText = this.smsText;
    this.http.post(Globals.URL + 'sendEmail', this.currentOrder.info).subscribe(response => {
    });
  }

}
