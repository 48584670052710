import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Globals } from '../globals';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';


export const token = true;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  user = {
    name: '',
    password: ''
  };
  loginData = [];
  headers;

  preloader = false;

  constructor(private http: HttpClient, private router: Router, public toastr: ToastrManager) { }

  ngOnInit() {
  }

  login() {

    this.preloader = true;

    this.http.post(Globals.URL + 'login', this.user).subscribe(response => {
      this.loginData = [];
      this.loginData.push(response);

      if (this.loginData[0].status === 'error') {
        this.toastr.errorToastr(this.loginData[0].message);

      } else if (this.loginData[0].data.token_type === 'Bearer') {
        localStorage.setItem('access_token', this.loginData[0].data.access_token);
        localStorage.setItem('token_type', this.loginData[0].data.token_type);
        localStorage.setItem('user_type', this.loginData[0].user.type);
        this.toastr.successToastr('Bun venit ! ' + this.user.name);
        this.router.navigate(['/admin']);
      }
      this.preloader = false;
    });
  }

}
