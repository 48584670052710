import { Prices } from '../../settings/prices/prices.model';
import { Globals } from '../../../globals';
import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ActivatedRoute } from '@angular/router';
import { log } from 'util';

@Component({
  selector: 'app-add-order-for-client',
  templateUrl: './add-order-for-client.component.html',
  styleUrls: ['./add-order-for-client.component.scss']
})
export class AddOrderForClientComponent implements OnInit {
  ROOT_URL = 'http://mojostudio.go.ro/bruno_api/index.php/api/';


  order = {
    info: {
      name: '',
      email: '',
      street: '',
      phone: '',
      city: '',
      number: '',
      observations: '',
      received_date: '',
      advance_payment: '',
      delivery: false,
      carpetMeters: 0,
      status: 0,
      finished_at: '',
      total_price: 0,
      delivery_date: '',
      recived_date: '',
      carpetTotalPrice: 0,
      blanketTotalPrice: 0,
      pillowTotalPrice: 0,
      otherTotalPrice: 0,
      series: '',
      order_number: '',
      emergency_tax: false,
      client_type: 0,
      CUI: '',
      J: '',
      carpetsNumber: 0,
      blanketsNumber: 0,
      pillowsNumber: 0,
      othersNumber: 0,
    },
    carpetsList: [],
    blanketsList: [],
    pillowsList: [],
    othersList: []
  };

  deliveryPrice = 0;

  carpet = {
    length: 0,
    width: 0,
    extra: '',
    price: ''
  };

  blanket = {
    name: '',
    extra: 0,
    price: 0
  };

  pillow = {
    name: '',
    extra: 0,
    price: 0
  };

  other = {
    name: '',
    extra: 0,
    price: 0
  };
  textButton: string;
  prices;
  param: any;
  orderTemp: any;
  clients: any;
  ordersList = [];
  blanketFocus: number;
  pillowFocus: number;
  othersFocus: number;
  extraBlanket: any;
  extraPillow: any;
  extraOthers: any;
  info: any;
  orderNumber: any;


  constructor(private http: HttpClient, public toastr: ToastrManager, private route: ActivatedRoute) {
    this.prices = new Prices();
    this.route.params.subscribe(params => {
      this.param = params;
    }
    );
  }

  ngOnInit() {
    this.getPrices();
    this.getClient();
    this.blanketFocus = 0;
    this.pillowFocus = 0;
    this.othersFocus = 0;
  }

  //  Get Prices
  getPrices() {
    this.http.get(Globals.URL + 'getCurrentPrices').subscribe(response => {
      this.prices = response;
    });
  }

  getClients() {
    this.http.get(Globals.URL + 'clients').subscribe(response => {
      this.clients = response;
    });
  }



  // Generate Carpets
  generateCarpets() {
    if (this.order.info.carpetsNumber !== 0) {
      this.order.carpetsList = [];
    }
    for (let i = 0; i < this.order.info.carpetsNumber; i++) {
      this.carpet = this.carpet = {
        length: 0,
        width: 0,
        extra: '',
        price: ''
      };
      this.order.carpetsList.push(this.carpet);
    }
    this.toastr.successToastr('', 'Comanda va avea ' + this.order.info.carpetsNumber + ' covoare');
    this.order.info.carpetTotalPrice = 0;
    this.order.info.carpetMeters = 0;
  }
  // Generate Blankets
  generateblankets() {
    if (this.order.info.blanketsNumber !== 0) {
      this.order.blanketsList = [];
    }
    for (let i = 0; i < this.order.info.blanketsNumber; i++) {
      this.blanket = this.blanket = {
        name: '',
        extra: 0,
        price: 0
      };
      this.blanket.price = this.prices.blanket;
      this.order.blanketsList.push(this.blanket);
      this.order.info.blanketTotalPrice = this.order.info.blanketTotalPrice + this.blanket.price;
    }
    this.toastr.successToastr('', 'Comanda va avea ' + this.order.info.blanketsNumber + ' pilote');
    this.order.info.total_price = this.order.info.total_price + this.order.info.blanketTotalPrice;

  }
  // Generate Pillows
  generatePillows() {
    if (this.order.info.pillowsNumber !== 0) {
      this.order.pillowsList = [];
    }
    for (let i = 0; i < this.order.info.pillowsNumber; i++) {
      this.pillow = this.pillow = {
        name: '',
        extra: 0,
        price: 0
      };
      this.pillow.price = this.prices.pillow;
      this.order.pillowsList.push(this.pillow);
      this.order.info.pillowTotalPrice = this.order.info.pillowTotalPrice + Number(this.pillow.price);

    }
    this.toastr.successToastr('', 'Comanda va avea ' + this.order.info.pillowsNumber + ' pături');
    this.order.info.total_price = this.order.info.total_price + this.order.info.pillowTotalPrice;

  }
  // Generate Others
  generateOthers() {
    if (this.order.info.othersNumber !== 0) {
      this.order.othersList = [];
    }
    for (let i = 0; i < this.order.info.othersNumber; i++) {
      this.other = this.other = {
        name: '',
        extra: 0,
        price: 10
      };
      this.other.price = this.prices.other;
      this.order.othersList.push(this.other);
      this.order.info.otherTotalPrice = this.order.info.otherTotalPrice + Number(this.other.price);
    }
    this.toastr.successToastr('', 'Comanda va avea ' + this.order.info.othersNumber + ' alte elemente');
    this.order.info.total_price = this.order.info.total_price + this.order.info.otherTotalPrice;


  }
  // Add Carpet Extra Price

  addExtraPriceCarpet(item) {

    if (item.extra === null) {
      item.price = item.price + (item.width * item.length) * this.prices.carpetExtra;
    }
    if (item.extra === '') {
      item.price = item.price + (item.width * item.length) * this.prices.carpetExtra;
    }
    if (item.extra === true || item.extra === 1) {
      item.price = item.price - (item.width * item.length) * this.prices.carpetExtra;
    }
    if (item.extra === false || item.extra === 0) {
      item.price = item.price + (item.width * item.length) * this.prices.carpetExtra;
    }
    this.updateCarpetPrice(item);
    // tslint:disable-next-line:max-line-length
    this.order.info.total_price = this.order.info.carpetTotalPrice + this.order.info.blanketTotalPrice + this.order.info.pillowTotalPrice + this.order.info.otherTotalPrice;
  }


  // Add Blanket Extra Price
  addExtraPriceBlanket(item) {
    this.order.info.blanketTotalPrice = this.addExtra(item, Number(this.prices.blanketExtra), this.order.info.blanketTotalPrice);
    // tslint:disable-next-line:max-line-length
    this.order.info.total_price = this.order.info.carpetTotalPrice + this.order.info.blanketTotalPrice + this.order.info.pillowTotalPrice + this.order.info.otherTotalPrice;
    // this.addExtra(item, Number(this.prices.blanketExtra));
  }

  // Add Pillow Extra Price
  addExtraPricePillow(item) {
    this.order.info.pillowTotalPrice = this.addExtra(item, Number(this.prices.pillowExtra), this.order.info.pillowTotalPrice);
    // tslint:disable-next-line:max-line-length
    this.order.info.total_price = this.order.info.carpetTotalPrice + this.order.info.blanketTotalPrice + this.order.info.pillowTotalPrice + this.order.info.otherTotalPrice;

    // this.addExtra(item, Number(this.prices.pillowExtra));
  }

  // Add Other Extra Price
  addExtraPriceOther(item) {
    this.order.info.otherTotalPrice = this.addExtra(item, Number(this.prices.otherExtra), this.order.info.otherTotalPrice);
    // tslint:disable-next-line:max-line-length
    this.order.info.total_price = this.order.info.carpetTotalPrice + this.order.info.blanketTotalPrice + this.order.info.pillowTotalPrice + this.order.info.otherTotalPrice;

  }

  // Add Extra Price
  addExtra(item, extraPrice, itemTotalPrice) {

    if (item.extra === null) {
      itemTotalPrice += extraPrice;
      item.price = item.price + extraPrice;

    }
    if (item.extra === '') {
      itemTotalPrice += extraPrice;
      item.price = item.price + extraPrice;

    }
    if (item.extra === true || item.extra === 1) {
      itemTotalPrice -= extraPrice;
      item.price = item.price - extraPrice;

    }
    if (item.extra === false || item.extra === 0) {
      itemTotalPrice += extraPrice;
      item.price = item.price + extraPrice;
    }
    return itemTotalPrice;
  }


  // Calculate Total Carpet Meters
  totalCarpetMeters() {
    this.order.carpetsList.forEach(element => {
      this.order.info.carpetMeters = this.order.info.carpetMeters + (Number(element.length) * Number(element.width));
    });
  }
  // Calculate Total Carpets Price
  totalCarpetPrice() {
    this.order.carpetsList.forEach(element => {
      this.order.info.carpetTotalPrice = this.order.info.carpetTotalPrice + Number(element.price);
    });
  }

  // Update Total Blanket Price on change
  updateCarpetPrice(item) {
    this.order.info.carpetTotalPrice = 0;
    this.order.info.carpetMeters = 0;
    this.totalCarpetPrice();
    this.totalCarpetMeters();

    if (item.width !== '' || item.length !== '') {
      // this.order.info.carpetMeters = this.order.info.carpetMeters + Number(item.length * item.width);
      // tslint:disable-next-line:max-line-length
      this.order.info.total_price = this.order.info.carpetTotalPrice + this.order.info.blanketTotalPrice + this.order.info.pillowTotalPrice + this.order.info.otherTotalPrice;
    }
  }

  // Update Total Blanket Price on change
  updateBlanketPrice(item) {
    if (item.name === '') {
      this.blanketFocus = 0;
      item.price = 0;
    }
    this.blanketFocus = this.blanketFocus + 1;
    if (this.blanketFocus <= 1) {
      this.order.info.blanketTotalPrice = this.order.info.blanketTotalPrice + Number(item.price);
      // tslint:disable-next-line:max-line-length
      this.order.info.total_price = this.order.info.carpetTotalPrice + this.order.info.blanketTotalPrice + this.order.info.pillowTotalPrice + this.order.info.otherTotalPrice;
    }
  }

  // Update Total Pillow Price on change
  updatePillowPrice(item) {
    if (item.name === '') {
      this.pillowFocus = 0;
      item.price = 0;
    }
    this.pillowFocus = this.pillowFocus + 1;
    if (this.pillowFocus <= 1) {
      this.order.info.pillowTotalPrice = this.order.info.pillowTotalPrice + Number(item.price);
      // tslint:disable-next-line:max-line-length
      this.order.info.total_price = this.order.info.carpetTotalPrice + this.order.info.blanketTotalPrice + this.order.info.pillowTotalPrice + this.order.info.otherTotalPrice;
    }
  }

  // Update Total Others Price on change
  updateOthersPrice(item) {
    if (item.name === '') {
      this.othersFocus = 0;
      item.price = 0;
    }
    this.othersFocus = this.othersFocus + 1;
    if (this.othersFocus <= 1) {
      this.order.info.otherTotalPrice = this.order.info.otherTotalPrice + Number(item.price);
      // tslint:disable-next-line:max-line-length
      this.order.info.total_price = this.order.info.carpetTotalPrice + this.order.info.blanketTotalPrice + this.order.info.pillowTotalPrice + this.order.info.otherTotalPrice;
    }
  }

  // POST / PATCH order
  postOrder() {
    if (this.order.info.delivery === true) {
      this.order.info.total_price += this.prices.delivery;
    }
    if (this.order.info.emergency_tax === true) {
      this.order.info.total_price += this.prices.emergency_tax;
    }

    this.http.post(Globals.URL + 'orders', this.order).subscribe(respone => {
      localStorage.removeItem('clientID');
    });
  }


  getOrderNumber() {
    this.http.get(Globals.URL + 'orderNumber').subscribe(response => {
      this.orderNumber = response;
      this.order.info.series = this.orderNumber.series;
      this.order.info.order_number = this.orderNumber.number;
    });
  }

  resetOrder() {
    this.order = {
      info: {
        name: '',
        email: '',
        street: '',
        phone: '',
        city: '',
        number: '',
        observations: '',
        received_date: '',
        advance_payment: '',
        delivery: false,
        carpetMeters: 0,
        status: 0,
        finished_at: '',
        total_price: 0,
        delivery_date: '',
        recived_date: '',
        carpetTotalPrice: 0,
        blanketTotalPrice: 0,
        pillowTotalPrice: 0,
        otherTotalPrice: 0,
        series: '',
        order_number: '',
        emergency_tax: false,
        client_type: 0,
        CUI: '',
        J: '',
        carpetsNumber: 0,
        blanketsNumber: 0,
        pillowsNumber: 0,
        othersNumber: 0,
      },
      carpetsList: [],
      blanketsList: [],
      pillowsList: [],
      othersList: []
    };
  }

  getClient() {
    this.http.get(Globals.URL + 'clients/' + localStorage.getItem('clientID')).subscribe(response => {
      this.info = response;

      this.order.info = this.info;

      this.info.carpetsNumber = 0;
      this.info.blanketsNumber = 0;
      this.info.pillowsNumber = 0;
      this.info.othersNumber = 0;

      this.info.total_price = 0;
      this.info.carpetTotalPrice = 0;
      this.info.blanketTotalPrice = 0;
      this.info.pillowTotalPrice = 0;
      this.info.otherTotalPrice = 0;
    });
  }
}
