import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Globals } from '../../globals';
import { SMS } from './../_model/sms.model';

@Injectable({
  providedIn: 'root'
})
export class SMSService {

  constructor(private http: HttpClient) { }

  postSMS(data) {
    return this.http.post(Globals.URL + 'sendSms', data);
  }

  postMultipleSMS(data) {
    return this.http.post(Globals.URL + 'sendSmsMultiple', data);
  }

  showLogByClient(client_id) {
    return this.http.get(Globals.URL + 'showLogByClient/' + client_id);
  }

  showLogByOrder(order_id) {
    return this.http.get(Globals.URL + 'showLogByOrder/' + order_id);
  }

}
