import {Component, OnInit} from '@angular/core';
import {Globals} from '../../globals';
import {Router} from '@angular/router';
import {ToastrManager} from 'ng6-toastr-notifications';


@Component({
  selector: 'app-admin-nav-bar',
  templateUrl: './admin-nav-bar.component.html',
  styleUrls: ['./admin-nav-bar.component.scss']
})
export class AdminNavBarComponent implements OnInit {

  constructor(private router: Router, public toastr: ToastrManager) {
  }

  user = {
    name: '',
    email: '',
    password: ''
  };
  userType: string;

  ngOnInit() {
    this.checkAuth();
    this.getUserType();

  }

  getUserType() {
    this.userType = localStorage.getItem('user_type');
  }


  checkAuth() {
    if (localStorage.getItem('token_type') === null) {
      this.router.navigate(['']);
    }
  }

  checkIfSaveOrder() {
    if (this.router.url.includes('order') || this.router.url.includes('addOrder')) {
      if (localStorage.getItem('order') !== null || localStorage.getItem('clientID') !== null) {
        if (confirm('Sunt modificări nesalvate, doriți să continuați ?')) {
          localStorage.removeItem('order');
          localStorage.removeItem('clientID');
        } else {
          this.router.navigate([this.router.url]);
        }
      }
    }
  }

  // logout() {
  //   localStorage.removeItem('access_token');
  //   localStorage.removeItem('user_type');
  //   this.toastr.successToastr('La revedere ! ' + this.user.name);
  //   this.user = {
  //     name: '',
  //     email: '',
  //     password: ''
  //   };
  //   this.router.navigate(['/admin']);
  // }
}
