import {SMSService} from './../../_services/sms.service';
import {Prices} from '../../settings/prices/prices.model';
import {Globals} from '../../../globals';
import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ToastrManager} from 'ng6-toastr-notifications';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {SMS} from '../../_model/sms.model';
import {OrderNumber} from '../../settings/order-number/orderNumber.model';

@Component({
  selector: 'app-add-order',
  templateUrl: './add-order.component.html',
  styleUrls: ['./add-order.component.scss']
})
export class AddOrderComponent implements OnInit {
  @ViewChild('inputName') public inputName: ElementRef;

  order = {
    info: {
      id: 0,
      finishedPrice: 1,
      color: '',
      created_at: '',
      name: '',
      email: '',
      street: '',
      phone: '',
      city: '',
      number: '',
      observations: '',
      received_date: '',
      advance_payment: 0,
      paid: false,
      delivery: false,
      delivery2: false,
      remote: false,
      carpetMeters: 0,
      status: 0,
      finished_at: '',
      total_price: 0,
      delivery_date: '',
      carpetTotalPrice: 0,
      blanketTotalPrice: 0,
      pillowTotalPrice: 0,
      otherTotalPrice: 0,
      series: '',
      order_number: 0,
      emergency_tax: false,
      client_type: 0,
      client_problem: 0,
      CUI: '',
      J: '',
      carpetsNumber: null,
      blanketNumber_old: 0,
      blanketsNumber: null,
      pillowsNumber: null,
      pillowsNumber_old: 0,
      othersNumber: null,
      othersNumber_old: 0,
      otherTotalPrice_old: 0
    },
    carpetsList: [],
    blanketsList: [],
    pillowsList: [],
    othersList: []
  };

  deliveryPrice = 0;

  carpet = {
    length: null,
    width: null,
    extra: 0,
    price: 0
  };

  blanket = {
    name: '',
    extra: 0,
    price: 0
  };

  pillow = {
    name: '',
    extra: 0,
    price: 0
  };

  other = {
    name: '',
    extra: 0,
    price: 0
  };

  textButton: string;
  prices;
  param: any;
  orderTemp: any;
  clients = [];
  ordersList = [];
  blanketFocus: number;
  pillowFocus: number;
  othersFocus: number;
  extraBlanket: any;
  extraPillow: any;
  extraOthers: any;
  info: any;
  string: any;
  orderNumber: any;

  localOrder: any;

  autoFillClient: boolean;
  editMode: boolean;
  clientOrderMode = false;

  SMS: SMS;

  biggerAdvancePayment = false;
  settings;
  showModal = false;

  constructor(
    private http: HttpClient,
    public toastr: ToastrManager,
    private route: ActivatedRoute,
    private _location: Location,
    private router: Router,
    private el: ElementRef,
    private smsService: SMSService
  ) {
    this.orderNumber = new OrderNumber();
    this.prices = new Prices();
    this.route.params.subscribe(params => {
      this.param = params;
    });
    this.SMS = new SMS();
  }


  ngOnInit() {

    this.getPrices();
    if (localStorage.getItem('order') != null) {
      this.getLocalStorageOrder();
    }

    if (localStorage.getItem('addOrderFromClient') === 'true') {
      this.http.get(Globals.URL + 'clients/' + localStorage.getItem('clientID'))
        .subscribe(response => {
          this.orderTemp = response;
          this.order.info = this.orderTemp;
          this.order.info.carpetsNumber = null;
          this.order.info.blanketsNumber = null;
          this.order.info.pillowsNumber = null;
          this.order.info.othersNumber = null;
          this.order.info.carpetTotalPrice = 0;
          this.order.info.blanketTotalPrice = 0;
          this.order.info.pillowTotalPrice = 0;
          this.order.info.otherTotalPrice = 0;
          this.order.info.total_price = 0;
          this.clientOrderMode = true;
        });
    }

    if (this.param.id != null) {
      this.http
        .get(Globals.URL + 'orders/' + this.param.id)
        .subscribe(response => {
          this.orderTemp = response;
          this.order = this.orderTemp;
          this.textButton = 'Modifică comanda';
          this.checkAllExtra();
          // this.checkboxPaid();
        });
      this.autoFillClient = true;
      this.editMode = true;
    } else {
      this.textButton = 'Adaugă comanda';
      this.getOrderNumber();
      this.autoFillClient = false;
      this.editMode = false;
    }
    this.blanketFocus = 0;
    this.pillowFocus = 0;
    this.othersFocus = 0;

    this.checkAuth();
    this.getSettings();
    // this.checkboxPaid();
  }

  getSettings() {
    return this.http.get(Globals.URL + 'settings').subscribe(response => {
      this.settings = response;

      console.log(this.settings);
    });
  }


  checkAllExtra() {
    // for (const item of this.order.carpetsList) {
    //   item.extra = this.checkExtraString(item.extra);
    // }
    for (const item of this.order.blanketsList) {
      item.extra = this.checkExtraNumber(item.extra);
    }
    for (const item of this.order.pillowsList) {
      item.extra = this.checkExtraNumber(item.extra);
    }
  }

  checkExtraString(extra) {
    if (extra === '1') {
      return true;
    } else {
      return false;
    }
  }

  checkExtraNumber(extra) {
    if (extra === 1) {
      return true;
    } else {
      return false;
    }
  }

  checkAuth() {
    if (localStorage.getItem('token_type') === null) {
      this.router.navigate(['']);
    }
  }

  getLocalStorageOrder() {
    this.localOrder = localStorage.getItem('order');
    this.localOrder = JSON.parse(this.localOrder);
    this.order = this.localOrder;
    this.checkAllExtra();
  }

  //  Get Prices
  getPrices() {
    this.http.get(Globals.URL + 'getCurrentPrices').subscribe(response => {
      this.prices = response;
    });
  }

  // Get order number
  getOrderNumber() {
    this.http.get(Globals.URL + 'orderNumber').subscribe(response => {
      this.orderNumber = response;
      this.order.info.series = this.orderNumber.series;
      this.order.info.order_number = this.orderNumber.number;
      this.order.info.color = this.orderNumber.color;
    });
  }

  postOrderNumber() {
    return this.http
      .patch(Globals.URL + 'orderNumber', this.orderNumber)
      .subscribe(
        result1 => {
          this.toastr.successToastr('', 'Talon actualizat');
          if (this.param.id != null) {
            // console.log(this.order);
            this.http
              .patch(Globals.URL + 'orders/' + this.param.id, this.order)
              .subscribe(response => {
                // console.log(response);

                this.toastr.successToastr('', 'Comandă actualizată');
              });
          }
        },
        error => {
          this.toastr.errorToastr(Globals.mesageError);
        });
  }

  checkOrderNumber() {
    this.orderNumber.series = +this.order.info.series;
    this.orderNumber.number = +this.order.info.order_number;
    this.orderNumber.color = this.order.info.color;
    if (this.param.id != null) {
      this.orderNumber.order_id = +this.param.id;
    }
    return this.http
      .post(Globals.URL + 'checkOrderNumber', this.orderNumber);

  }

  addOrderNumber() {

    this.checkOrderNumber().subscribe(
      result => {
        if (result == 1) {
          this.postOrderNumber();
          console.log(this.checkOrderNumber());
        } else {
          console.log(this.checkOrderNumber());
          if (confirm('Bonul există în altă comandă, continuați ? ')) {
            this.postOrderNumber();
            return false;
          } else {
            return false;
          }
        }
      }
    );


    // this.http
    //   .post(Globals.URL + 'checkOrderNumber', this.orderNumber)
    //   .subscribe(
    //     result => {
    //       if (result == 1) {
    //         this.postOrderNumber();
    //       } else {
    //         if (confirm('Bonul există în altă comandă, continuați ? ')) {
    //           this.postOrderNumber();
    //           return false;
    //         } else {
    //           return false;
    //         }
    //       }
    //
    //     },
    //     error => {
    //       this.toastr.errorToastr(Globals.mesageError);
    //     });
  }

  // Auto fill customer name
  autoFillCustomer(item) {
    this.order.info.name = item.name;
    this.order.info.phone = item.phone;
    this.order.info.email = item.email;
    this.order.info.street = item.street;
    this.toastr.successToastr('Client găsit', 'Autocompletare');

    this.autoFillClient = true;
  }

  checkIfDelivery(delivery) {
    if (delivery === 1) {
      console.log(this.prices);
      return this.prices.delivery;
    } else {
      return 0;
    }
  }

  checkIfEmergency(emergency) {
    if (emergency === 1) {
      // console.log(this.prices.emergency_tax);
      return this.prices.emergency_tax;
    } else {
      return 0;
    }
  }

  paid() {
    const delivery = +this.checkIfDelivery(this.order.info.delivery);
    const emergency = +this.checkIfEmergency(this.order.info.emergency_tax);
    const total_price = this.order.info.total_price + delivery + emergency;

    if (this.order.info.advance_payment != total_price) {
      this.order.info.advance_payment = 0;
      this.order.info.advance_payment = total_price;
    } else {
      this.order.info.advance_payment = 0;
    }
    this.localSaveOrder();
    // this.checkboxPaid();
    // this.calculateTotalPrice();
  }

  calculateTotalMeters(length, width) {
    let result = length * width;
    result = +result.toFixed(2);
    return result;
  }

  messageWarningMaxElements(element) {
    const elements: any[] = ['covoare', 'pilote', 'pături', 'altele'];
    return this.toastr.warningToastr('Atentie', 'Comanda poate avea maxim 50 de ' + elements[element], {maxShown: 2});
  }

  checkType(extra) {
    return typeof extra;
  }

  increaseCarpets() {
    this.order.info.carpetsNumber += 1;

    if (this.order.info.carpetsNumber < 0) {
      this.order.info.carpetsNumber = null;
    }
    if (this.order.info.carpetsNumber < 51) {
      this.carpet = {
        length: null,
        width: null,
        extra: 0,
        price: 0,
      };
      this.order.carpetsList.push(this.carpet);
    } else {
      this.messageWarningMaxElements(0);
    }

    this.updateCarpetPrice(null);
  }

  decreaseCarpets() {
    this.order.info.carpetsNumber -= 1;

    if (this.order.info.carpetsNumber < 0) {
      this.order.info.carpetsNumber = null;
    }

    this.order.carpetsList.pop();

    this.updateCarpetPrice(null);
  }

  // Generate Carpets
  generateCarpets() {
    if (this.order.info.carpetsNumber < 0) {
      this.order.info.carpetsNumber = null;
    }

    if (this.order.info.carpetsNumber !== 0) {
      this.order.carpetsList = [];
    }

    if (this.order.info.carpetsNumber < 51) {
      this.order.info.carpetTotalPrice = 0;
      this.order.info.carpetMeters = 0;

      for (let i = 0; i < this.order.info.carpetsNumber; i++) {
        this.carpet = {
          length: null,
          width: null,
          extra: 0,
          price: 0,
        };
        this.order.carpetsList.push(this.carpet);
      }

    } else {
      this.messageWarningMaxElements(0);
    }


    this.calculateTotalPrice();
  }


  // Generate Blankets
  generateBlankets() {

    if (this.order.info.blanketsNumber < 0) {
      this.order.info.blanketsNumber = null;
    }

    // check if the pillow has extra items and make all of them standard
    this.order.blanketsList.forEach(element => {
      if (element.extra) {
        element.extra = 0;
        this.order.info.total_price = this.order.info.total_price - this.prices.blanketExtra;
      }
    });

    if (this.order.info.blanketsNumber !== 0) {
      this.order.blanketsList = [];
    }

    if (this.order.info.blanketsNumber < 51) {

      for (let i = 0; i < this.order.info.blanketsNumber; i++) {
        this.blanket = {
          name: null,
          extra: null,
          price: 0
        };
        this.blanket.price = this.prices.blanket;
        this.order.blanketsList.push(this.blanket);
      }

      this.order.info.blanketTotalPrice = this.order.info.blanketsNumber * this.blanket.price;

    } else {
      this.messageWarningMaxElements(1);
    }
    this.calculateTotalPrice();
  }

  calculateBlanketPrice() {

    this.order.info.blanketTotalPrice = 0;

    this.order.blanketsList.forEach(element => {

      this.order.info.blanketTotalPrice += element.price;
    });
    // this.order.info.blanketTotalPrice = +this.order.info.blanketTotalPrice;
    this.calculateTotalPrice();
  }

  calculatePillowPrice() {

    this.order.info.pillowTotalPrice = 0;

    this.order.pillowsList.forEach(element => {

      this.order.info.pillowTotalPrice += element.price;
    });
    // this.order.info.pillowTotalPrice = +this.order.info.pillowTotalPrice;
    this.calculateTotalPrice();
  }

  // Generate Pillows
  generatePillows() {

    // do not let negative values to pillowNumber
    if (this.order.info.pillowsNumber < 0) {
      this.order.info.pillowsNumber = null;
    }

    // check if the pillow has extra items and make all of them standard
    this.order.pillowsList.forEach(element => {
      if (element.extra) {
        element.extra = 0;
        this.order.info.total_price = this.order.info.total_price - this.prices.pillowExtra;
      }
    });

    if (this.order.info.pillowsNumber !== 0) {
      this.order.pillowsList = [];
    }
    if (this.order.info.pillowsNumber < 51) {
      for (let i = 0; i < this.order.info.pillowsNumber; i++) {
        this.pillow = {
          name: null,
          extra: null,
          price: 0
        };
        this.pillow.price = this.prices.pillow;
        this.order.pillowsList.push(this.pillow);
      }

      this.order.info.pillowTotalPrice = this.order.info.pillowsNumber * this.pillow.price;

    } else {
      this.messageWarningMaxElements(2);
    }
    this.calculateTotalPrice();
  }


  // Generate Others
  generateOthers() {

    if (this.order.info.otherTotalPrice !== 0) {
      this.order.info.otherTotalPrice = 0;
    }

    if (this.order.info.othersNumber < 0) {
      this.order.info.othersNumber = null;
    }

    if (this.order.info.othersNumber !== 0) {
      this.order.othersList = [];
    }

    if (this.order.info.othersNumber < 51) {
      for (let i = 0; i < this.order.info.othersNumber; i++) {
        this.other = {
          name: null,
          extra: null,
          price: null
        };
        this.order.othersList.push(this.other);
      }
    } else {
      this.messageWarningMaxElements(3);
    }
    this.calculateTotalPrice();
  }

  increaseOthers() {
    this.order.info.othersNumber += 1;

    if (this.order.info.othersNumber < 0) {
      this.order.info.othersNumber = null;
    }

    if (this.order.info.othersNumber < 51) {
      this.other = {
        name: null,
        extra: null,
        price: null
      };
      this.order.othersList.push(this.other);
    } else {
      this.messageWarningMaxElements(3);
    }

    this.updateOthersPrice()
  }

  decreaseOthers() {
    this.order.info.othersNumber -= 1;

    if (this.order.info.othersNumber < 0) {
      this.order.info.othersNumber = null;
    }

    this.order.othersList.pop();

    this.updateOthersPrice()
  }


  // Add Carpet Extra Price
  addExtraPriceCarpet(item) {
    this.order.info.carpetTotalPrice = this.addExtra(
      item,
      parseFloat(this.prices.carpetExtra),
      this.order.info.carpetTotalPrice
    );
    this.calculateTotalPrice();
  }

  // Add Blanket Extra Price
  addExtraPriceBlanket(item) {

    this.order.info.blanketTotalPrice = this.addExtra(item, parseFloat(this.prices.blanketExtra), this.order.info.blanketTotalPrice);

    this.calculateTotalPrice();
  }

  // Add Pillow Extra Price
  addExtraPricePillow(item) {
    this.order.info.pillowTotalPrice = this.addExtra(item, parseFloat(this.prices.pillowExtra), this.order.info.pillowTotalPrice);

    this.calculateTotalPrice();
  }

  // Add Other Extra Price
  addExtraPriceOther(item) {
    this.order.info.otherTotalPrice = this.addExtra(
      item,
      parseFloat(this.prices.otherExtra),
      this.order.info.otherTotalPrice
    );
    this.calculateTotalPrice();
  }

  // Add Extra Price
  addExtra(item, extraPrice, itemTotalPrice) {
    if (item.extra === null) {
      itemTotalPrice += extraPrice;
      // tslint:disable-next-line:radix
      item.price = parseFloat(item.price) + extraPrice;
    } else if (item.extra === '') {
      itemTotalPrice += extraPrice;
      // tslint:disable-next-line:radix
      item.price = parseFloat(item.price) + extraPrice;
    } else if (item.extra === true || item.extra === 1) {
      itemTotalPrice -= extraPrice;
      // tslint:disable-next-line:radix
      item.price = parseFloat(item.price) - extraPrice;
      item.extra = false;
    } else if (item.extra === false || item.extra === 0) {
      itemTotalPrice += extraPrice;
      // tslint:disable-next-line:radix
      item.price = parseFloat(item.price) + extraPrice;
      item.extra = true;
    }
    return itemTotalPrice;
  }

  // Calculate Total Carpet Meters
  totalCarpetMeters() {
    this.order.carpetsList.forEach(element => {
      this.order.info.carpetMeters += this.calculateTotalMeters(element.length, element.width);
      this.order.info.carpetMeters = +this.order.info.carpetMeters.toFixed(2);
    });
  }


  checkIfNull(item) {
    if (item !== null) {
      item = +item.toFixed(2);
      return item;
    } else {
      return 0;
    }
  }

  calculateTotalPrice() {
    if (this.order.info.paid == true) {

      this.order.info.paid = false;
      this.order.info.advance_payment = 0;
    }
    this.order.info.total_price = 0;

    this.order.info.total_price =
      this.order.info.carpetTotalPrice +
      this.order.info.blanketTotalPrice +
      this.order.info.pillowTotalPrice +
      this.order.info.otherTotalPrice;

    this.order.info.total_price = +this.order.info.total_price.toFixed(2);
  }

  // Update Total Blanket Price on change
  updateCarpetPrice(item) {

    this.order.info.carpetTotalPrice = 0;
    this.order.info.carpetMeters = 0;

    this.totalCarpetPrice();
    this.totalCarpetMeters();
    this.calculateTotalPrice();
  }

  // Calculate Total Carpets Price
  totalCarpetPrice() {
    this.order.carpetsList.forEach(element => {

      element.price = this.calculateCarpetPrice(element.width, element.length, this.prices.carpet, element.extra);

      element.price = +element.price.toFixed(2);
      this.order.info.carpetTotalPrice += element.price;
    });
  }

  totalCarpetPriceModified() {
    this.order.info.carpetTotalPrice = 0;
    this.order.carpetsList.forEach(element => {
      element.price = +element.price.toFixed(2);
      this.order.info.carpetTotalPrice += element.price;
    });
    this.calculateTotalPrice();
  }

  updateOthersPrice() {
    this.order.info.otherTotalPrice = 0;

    this.order.othersList.forEach(element => {

      this.order.info.otherTotalPrice += this.checkIfNull(element.price);
    });

    this.order.info.otherTotalPrice = this.checkIfNull(this.order.info.otherTotalPrice);

    this.calculateTotalPrice();
  }


  calculateCarpetPrice(width, length, price, extra) {

    if (width != null && length != null) {
      const extra1 = +extra;
      const result = (width * length) * (price + extra1);
      if (result != null) {
        return result;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }

  // order search
  search(string) {
    if (string.length >= 3) {
      this.http.get(Globals.URL + 'searchClient/' + string).subscribe(
        response => {
          this.clients.push(response);
          this.clients[0] = response;
        },
        err => {
        }
      );
    }
  }

  // reset order
  resetOrder() {
    localStorage.removeItem('order');
    localStorage.removeItem('addOrderFromClient');

    if (this.param.id !== null) {
      this.order.blanketsList = [];
      this.order.carpetsList = [];
      this.order.pillowsList = [];
      this.order.othersList = [];
      this.order.info.carpetsNumber = null;
      this.order.info.pillowsNumber = null;
      this.order.info.othersNumber = null;
      this.order.info.blanketsNumber = null;
      this.order.info.total_price = 0;
      this.order.info.carpetMeters = 0;
      this.order.info.blanketTotalPrice = 0;
      this.order.info.carpetTotalPrice = 0;
      this.order.info.otherTotalPrice = 0;
      this.order.info.pillowTotalPrice = 0;
      this.order.info.othersNumber_old = 0;
      this.order.info.otherTotalPrice_old = 0;
      this.order.info.blanketNumber_old = 0;
      this.order.info.pillowsNumber_old = 0;
      this.order.info.advance_payment = 0;
      this.order.info.delivery = false;
      this.order.info.delivery2 = false;
      this.order.info.remote = false;
      this.order.info.emergency_tax = false;
      this.order.info.paid = false;
      this.order.info.observations = '';
      this.autoFillClient = true;
    } else {
      this.order = {
        info: {
          paid: false,
          id: 0,
          finishedPrice: 1,
          color: '',
          created_at: '',
          name: '',
          email: '',
          street: '',
          phone: '',
          city: '',
          number: '',
          observations: '',
          received_date: '',
          advance_payment: 0,
          delivery: false,
          delivery2: false,
          remote: false,
          carpetMeters: 0,
          status: 0,
          finished_at: '',
          total_price: 0,
          delivery_date: '',
          carpetTotalPrice: 0,
          blanketTotalPrice: 0,
          pillowTotalPrice: 0,
          otherTotalPrice: 0,
          series: '',
          order_number: 0,
          emergency_tax: false,
          client_type: 0,
          client_problem: 0,
          CUI: '',
          J: '',
          carpetsNumber: null,
          blanketNumber_old: 0,
          blanketsNumber: null,
          pillowsNumber: null,
          pillowsNumber_old: 0,
          othersNumber: null,
          othersNumber_old: 0,
          otherTotalPrice_old: 0
        },
        carpetsList: [],
        blanketsList: [],
        pillowsList: [],
        othersList: []
      };
    }

    this.autoFillClient = false;
  }

  // reset client
  resetClient() {
    this.order.info.name = '';
    this.order.info.phone = '';
    this.order.info.email = '';
    this.order.info.street = '';
    this.order.info.CUI = '';
    this.order.info.J = '';
    this.autoFillClient = false;
    this.localSaveOrder();
    localStorage.removeItem('order.info.name');
    this.clientOrderMode = false;
  }

  // save local the order
  localSaveOrder() {
    localStorage.setItem('order', JSON.stringify(this.order));
  }

  // restrict only numbers on inputs
  onlyNumbers(event) {
    return (event.charCode === 8 || event.charCode === 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  onlyAlpha(event: any) {
    const pattern = /^[a-zA-Z -]*$/;
    if (!pattern.test(event.target.value)) {
      event.target.value = event.target.value.replace(/[^a-zA-Z -]/g, '');
      // invalid character, prevent input
    }
  }

  checkAdvancePayment() {

    // if (parseFloat(this.order.info.advance_payment) > this.order.info.total_price) {
    //   this.biggerAdvancePayment = true;
    //   return true;
    // } else {
    this.biggerAdvancePayment = false;
    return false;
    // }
  }

  checkIfOrderIsEmpty() {
    if (Boolean(this.order.info.carpetsNumber) ||
      Boolean(this.order.info.blanketsNumber) ||
      Boolean(this.order.info.pillowsNumber) ||
      Boolean(this.order.info.othersNumber)) {
      return true;
    } else {
      return false;
    }
  }

  checkSubmit() {
    if (Boolean(this.order.info.name) && Boolean(this.order.info.phone)) {
      if (this.checkIfOrderIsEmpty()) {
        if (this.checkAdvancePayment()) {
          this.toastr.warningToastr('Avansul este mai mare ca plata totală', 'Obligatoriu', {maxShown: 2});
          return true;
        } else {
          if (this.param.id == null) {
            if (confirm('Doriți să printați bon ? ')) {
              this.showModal = true;
              return false;
            } else {
              this.toastr.successToastr('Trimis cu success', 'Success', {maxShown: 2});
              return false;
            }
          } else {
            this.toastr.successToastr('Trimis cu success', 'Success', {maxShown: 2});
            return false;
          }
        }
      } else {
        this.toastr.warningToastr('Nu aveți nimic adăugat în comandă', 'Obligatoriu', {maxShown: 2});
        return true;
      }
    } else {
      this.toastr.warningToastr('Lipsă nume sau telefon', 'Obligatoriu', {maxShown: 2});
      return true;
    }
  }

  actuallyPostOrder() {
    if (this.checkSubmit() === false) {

      if (this.param.id != null) {

        this.http
          .patch(Globals.URL + 'orders/' + this.param.id, this.order)
          .subscribe(response => {
            this._location.back();
            this.resetOrder();
          });
      } else {
        this.SMS.number = this.order.info.phone;
        this.SMS.message = 'Comanda a fost primită! \n O zi bună! \n Echipa Bruno';

        this.http.post(Globals.URL + 'orders', this.order).subscribe(response => {

            this.smsService.postSMS(this.SMS).subscribe(
              responseSMS => {
                if (this.showModal !== true) {

                  this.toastr.successToastr('Mesajul a fost transmis spre procesare!', 'Procesare!');
                }
              },
              error => {
                if (this.showModal !== true) {

                  this.toastr.errorToastr('Mesajul nu a fost livrat!', 'Eroare!');
                }
              });


            if (this.showModal === true) {
              // console.log(response);
              localStorage.removeItem('pdfInfo');
              this.toastr.dismissAllToastr();
              this.printPDF(response);
              this.router.navigateByUrl('/pdf');
              this.resetOrder();
            } else {
              this._location.back();
              this.resetOrder();
            }
          }
        );
      }

      localStorage.removeItem('addOrderFromClient');
    }
  }

  // POST / PATCH order
  postOrder() {

    this.checkOrderNumber().subscribe(
      result => {
        if (result == 1) {
          this.actuallyPostOrder();
          console.log(this.checkOrderNumber());
        } else {
          console.log(this.checkOrderNumber());
          if (confirm('Bonul există în altă comandă, continuați ? ')) {
            this.actuallyPostOrder();
            return false;
          } else {
            return false;
          }
        }
      }
    );


  }

  checkIfFinishedPrice(order) {

    for (const item of order.carpetsList) {
      if (item.price === 0) {
        this.order.info.finishedPrice = 0;
      }
    }
    for (const item of order.othersList) {
      if (item.price === 0) {
        this.order.info.finishedPrice = 0;
      }
    }
  }

  printPDF(response) {
    this.order.info.id = response.info.id;
    this.order.info.created_at = response.info.created_at;
    this.order.info.color = response.info.color;
    this.checkIfFinishedPrice(response);
    localStorage.setItem('pdfInfo', JSON.stringify(this.order.info));
  }
}
