import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Globals } from '../../globals';


@Component({
  selector: 'app-admin-header',
  templateUrl: './admin-header.component.html',
  styleUrls: ['./admin-header.component.scss']
})
export class AdminHeaderComponent implements OnInit {

  constructor(private router: Router, public toastr: ToastrManager) { }


  userType: string;

  ngOnInit() {
    this.checkAuth();
    this.getUserType();
  }


  redirectHome() {
    this.router.navigate(['/admin/listOrder']);
  }


  checkAuth() {
    if (localStorage.getItem('token_type') === null) {
      this.router.navigate(['']);
    }
  }

  getUserType() {
    this.userType = localStorage.getItem('user_type');
  }

  logout() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('user_type');
    localStorage.removeItem('token_type');
    this.toastr.successToastr('La revedere ! ');
    this.router.navigate(['']);
  }
}
