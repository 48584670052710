import {Injectable} from '@angular/core';

@Injectable()


export class Globals {
  static mesageSuccess = 'Lista a fost actualizata!';
  static mesageError = 'Eroare !';
  // static URL = 'http://brunoApi.icosmin.com.ro/index.php/api/';
  static URL = 'https://admin.spalatoria-bruno.ro/api/index.php/api/';
}
