
export class SMS {
  // public data: Info;
  public number: string;
  public message: string;
  public client_id: string;
  public id: string;
}

// export class Info {
//   public number: string;
//   public message: string ;
// }
