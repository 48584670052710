import {Component, HostListener, OnInit} from '@angular/core';
import { Location } from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {Globals} from '../../../globals';
import {Settings} from '../../settings/prices/settings.model';
import {Router, ActivatedRoute, ParamMap} from '@angular/router';
import {SMSService} from '../../_services/sms.service';

@Component({
  selector: 'app-fished-orders-list',
  templateUrl: './fished-orders-list.component.html',
  styleUrls: ['./fished-orders-list.component.scss']
})
export class FishedOrdersListComponent implements OnInit {

  public innerWidth: any;
  configFinished: any;
  // pagination
  orders: any;
  ordersNumber: any;

  showFinishedOrders = false;
  nameFilter = '';
  sortDate = 0;
  sortStatus = 0;
  sortId = 1;
  string: any;
  smsText = 'Comanda dvs a fost finalizata.';
  settings;
  userType: string;
  prices;

  logOrder;
  orderNotificationID;

  curentOrderInfo = {
    id: '',
    name: '',
    phone: '',
    email: '',
    street: '',
    created_at: '',
    carpetsNumber: 0,
    blanketsNumber: 0,
    pillowsNumber: 0,
    othersNumber: 0,
    total_price: 0,
    advance_payment: 0
  };

  currentOrder = {
    info: {
      name: '',
      phone: '',
      client_id: '',
      id: '',
      smsText: ''
    }
  };

  constructor(
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private smsService: SMSService
    ) {
    this.settings = new Settings();

    this.configFinished = {
      currentPage: 1,
      itemsPerPage: 20,
    }

    this.route.queryParamMap
      .map(params => params.get('pageFinished'))
      .subscribe(
        pageFinished => {
          this.configFinished.currentPage = pageFinished
        }
      );
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  ngOnInit() {
    this.innerWidth = window.innerWidth;
    // this.getOrders();
    // this.getPrices();
    // this.getUserType();
  }

  getUserType() {
    this.userType = localStorage.getItem('user_type');
  }

  getOrders() {
    const url = `${Globals.URL}getFinishedOrders?page=${0}&size=${this.configFinished.itemsPerPage}`;
    this.http.get(url).subscribe((response: any) => {
      this.orders = response.data.data;
      this.ordersNumber = response.data.totalItems;
    });

    this.getPrices();
    this.getUserType();
  }

  getPage(page) {
    const url = `${Globals.URL}getFinishedOrders?page=${page}&size=${this.configFinished.itemsPerPage}`;
    this.http.get(url).subscribe((response: any) => {
      this.orders = response.data.data;
      this.ordersNumber = response.data.totalItems;
    });

    this.configFinished.currentPage = page;
    this.location.go('/admin/listOrder?pageFinished=' + page);

    this.getPrices();
    this.getUserType();

    console.log(this.orders);
  }

  getPrices() {
    this.http.get(Globals.URL + 'prices').subscribe(response => {
      this.prices = response;
    });
  }

  printPDF() {
    localStorage.setItem('pdfInfo', JSON.stringify(this.curentOrderInfo));
  }

  showOrderInfo(item) {
    this.curentOrderInfo = item.info;
    this.http.get(Globals.URL + 'settings').subscribe(response => {
      this.settings = response;
    });
  }

  deleteOrders(item) {
    if (confirm('Ești sigur că vrei să ștergi aceasta comada!?')) {
      this.http
        .delete(Globals.URL + 'orders/' + item.info.id)
        .subscribe(result => {
          this.orders.splice(this.orders.indexOf(item.info.id), 1);
        });
    }
  }

  editOrder(id) {
    this.http.get(Globals.URL + 'orders/' + id).subscribe(response => {
    });
  }

  restoreOrder(item) {
    item.info.status = 1;
    this.http.patch(Globals.URL + 'orders/' + item.info.id, item).subscribe(result => {
      this.orders = this.orders.filter(u => u !== item);
      // this.router.navigateByUrl('admin/orderNumber', {skipLocationChange: true}).then(() =>
      //   this.router.navigate(['admin/listOrder']));
      this.getOrders();
    });
  }

  search(string) {
    if (string.length < 3) {
      this.getOrders();
    }
    if (string.length >= 3) {
      this.http
        .get(Globals.URL + 'searchFinishedOrders/' + string)
        .subscribe(response => {
          this.orders = response;
          this.ordersNumber = response['length'];
        });
    }
  }


  // Send Email
  chooseOrderForEmail(item) {
    this.currentOrder.info = item.info;
  }

  sendEmail() {
    this.currentOrder.info.smsText = this.smsText;
    this.http.post(Globals.URL + 'sendEmail', this.currentOrder.info).subscribe(response => {
    });
  }

  // *********

  chooseOrderForSms(item) {
    this.currentOrder.info = item.info;
  }

  sendSMS() {
    this.currentOrder.info.smsText = this.smsText;
    this.http.post(Globals.URL + 'sendEmail', this.currentOrder.info).subscribe(response => {
    });
  }

  showEmails(item) {
    this.http
      .get(Globals.URL + 'showLogByOrder/' + item.info.id)
      .subscribe(response => {
      });
  }

  sortById() {
    this.ordersSort(this.sortId, 'sortFinishedOrderById/');
  }

  sortByDate() {
    this.ordersSort(this.sortDate, 'sortFinishedOrderByDate/');
  }

  sortByStatus() {
    this.ordersSort(this.sortStatus, 'sortFinishedOrderByStatus/');
  }

  ordersSort(status, api) {
    if (status === 0) {
      this.getOrders();
    }
    if (status === 1) {
      this.http.get(Globals.URL + api + 0).subscribe(response => {
        this.orders = response;
      });
    }
    if (status === 2) {
      this.http.get(Globals.URL + api + 1).subscribe(response => {
        this.orders = response;
      });
    }
  }

  getHistoryMessage(item) {

    this.orderNotificationID = item.info.id;

    this.smsService.showLogByOrder(item.info.id).subscribe(
      response => {
        this.logOrder = response;
      },
      // tslint:disable-next-line:no-shadowed-variable
      error => {
      }
    );
  }

}
